import React, { Component } from 'react'
import { Button, Header, Segment, Sticky } from 'semantic-ui-react'
import './ProfileSegment.css';

class PlanSegment extends Component {

  convertPeriod(length, unit) {
    let period = `${length} ${unit}`.slice(0, -1);
    if (length === 12 && unit === "months") {
      period = "year"
    } else if (length === 3 && unit === "months") {
      period = "3 months"
    }
    return period
  }

  render() {
    return (
      <Sticky>
        <Segment className="profileSegment">
          <div className="separator">
          </div>
          <Button.Group vertical className="profileEditLinks">
            <Button className="editProfileButton" onClick={this.props.handleEditClick}>
              Edit Profile
            </Button>
            <Button className="changePasswordButton" onClick={this.props.handleChangePasswordClick}>
              Change Password
            </Button>
          </Button.Group>
          <Header as="h2" className="profileSegmentHeader">
              Profile
          </Header>
          <Header as="h4" className="profileFieldHeader">
              First Name
            <div className="profileFieldData">
              {this.props.profile.first_name}
            </div>
          </Header>
          <Header as="h4" className="profileFieldHeader">
              Last Name
            <div className="profileFieldData">
              {this.props.profile.last_name}
            </div>
          </Header>
          <Header as="h4" className="profileFieldHeader">
              Email
            <div className="profileFieldData">
              {this.props.profile.email}
            </div>
          </Header>
          <div className="changePasswordLink">
          </div>          
        </Segment>
      </Sticky>
    )
  }
}

export default PlanSegment;