import React, { Component } from 'react';
import { Container, Grid, Header, Message, Transition } from 'semantic-ui-react'
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm.js'
import { Helmet } from "react-helmet-async";
import './ForgotPassword.css';

class ForgotPassword extends Component {
  constructor () {
    super()
    this.state = {
      error: false,
      errorMessage: ""
    };
    this.handleError = this.handleError.bind(this);
  }

  handleError(error) {
      this.setState({error: true});
      this.setState({errorMessage: error});
      setTimeout(
        function() {
          this.setState({ error: false});
          this.setState({ errorMessage: "" });
        }
        .bind(this),
        4000
      );
  }

  render() {
    return (
      <Container fluid className="loginPageMainContentContainer">
        <Helmet>
          <title>
            Reset Password 
          </title>
        </Helmet>
        <Grid stackable className="loginPageMainContentGrid">
          <Grid.Row reversed="tablet computer" className="loginPageMainContentRow">
            <Grid.Column mobile={16} tablet={8} computer={8} className="rightColumn">
              <Grid verticalAlign='middle' textAlign='center' className="authGrid">
                <Grid.Row className="loginHeaderRow">
                  <Grid.Column width="12" textAlign="left">
                    <div className="separator">
                    </div>
                    <Header as="h2" className="loginHeader">
                      Reset Password
                    </Header>
                    <div className="forgotText">
                      Enter your email address and we will send you a link to reset your password.
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="loginErrorRow">     
                  <Grid.Column width="12" textAlign="left">
                    <Transition visible={this.state.error} animation='scale' duration={{show: 500, hide: 0}}>
                      <Message error header={this.state.errorMessage} />
                    </Transition>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="loginFormRow">
                  <Grid.Column width="12" textAlign="left">
                    <ForgotPasswordForm onErrorMessage={this.handleError} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} className="leftColumn">
            </Grid.Column>
          </Grid.Row>            
        </Grid>
      </Container>         
    )
  }
}

export default ForgotPassword;
