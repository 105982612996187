import * as Auth from "devise-token-client";

export default function putAuth(url, fields, options = {}, params = {}) {
  options.method = "PUT";
  if (Object.keys(params).length === 0) {
    options.headers = Auth.requestHeaders();
  } else {
    options.headers = params;
  }
  options.headers["Accept"] = "application/json";
  options.headers["Content-Type"] = "application/json";
  options.body = fields;

  return window
    .fetch(url, options)
    .catch((error) => console.log(error))
    .then((response) => {
      if (response.headers.get("access-token")) {
        Auth.persistToken(response.headers);
      }
      return response;
    });
}
