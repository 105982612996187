import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'
import { Formik, Form, Field } from 'formik';
import { isSignedIn, persistToken } from 'devise-token-client';
import history from '../../../history';
import './LoginForm.css';

class LoginForm extends Component {
  constructor () {
    super()
    this.state = {
      email: "",
      password: ""
    };
  }

  fetch(endpoint, fields) {
    const reqObj = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: fields
    }
    fetch(endpoint, reqObj)
    .then(response => {
      persistToken(response.headers)
      if(isSignedIn()){
        history.push({
          pathname: "/",
          state: {
            visible: true,
            success: true,
            message: "Login successful!"
          }
        })
      }
      return response.json();
    })
    .then (jsonResponse => {
      if (jsonResponse.success === false) {
        throw new Error(jsonResponse.errors[0])
      }
    })
    .catch(error => {
      this.props.onErrorMessage(error.message)
    })
  }

  handleSubmit(fields) {
    const data = JSON.stringify(fields)
    this.fetch('/api/auth/sign_in', data)
  }

  getField(name, placeholder, type) {
    return (
      <>
        <div className='field'>
          <Field
            name={name}
            placeholder={placeholder}
            type={type} />
        </div>
        <div className="errorDiv mobileErrorDiv">
        </div>
      </>
    )
  }

  render() {
    return (
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        onSubmit={fields => {
          this.handleSubmit(fields)
        }}
      >
        {({ errors, status, touched }) => (
          <Form noValidate className="ui form">
            {this.getField("email", "Email", "email")}
            {this.getField("password", "Password", "password")}
            <Button primary type="submit">
              Login
            </Button>
          </Form>
        )}
      </Formik>
    )
  }
}

export default LoginForm;
