import React, { Component } from 'react';
import { Grid, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PaginationBar from '../PaginationBar/PaginationBar.js'; 
import CellGrid from '../CellGrid/CellGrid.js'; 
import './SearchResults.css';

class SearchResults extends Component {

  showSearchTips() {
    return (
      <Grid stackable centered>
        <Grid.Row>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="searchSegmentColumn">
            <Segment compact secondary size="large">
              Tell us what you are looking for!
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
      </Grid>
    )
  }

  showSearchResults() {
    return (
      <Grid stackable centered>
        <Grid.Row>
          <PaginationBar 
            position="topPagination"
            page={this.props.currentPage} 
            totalpages={this.props.totalPages} 
            onPageChange={this.props.onPageChange} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
            <CellGrid presentations={this.props.presentations} q={this.props.q} presentationsLoaded={this.props.presentationsLoaded} columns={4} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <PaginationBar
            position="bottomPagination"
            page={this.props.currentPage} 
            totalpages={this.props.totalPages} 
            onPageChange={this.props.onPageChange} />
        </Grid.Row>
      </Grid>
    )
  }

  showNoSearchResults() {
    return (
      <Grid stackable centered>
        <Grid.Row>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="searchSegmentColumn">
            <Segment compact secondary size="large">
              <p>
                Your search - 
                {' '}
                <strong>{
                  this.props.q}
                </strong>
                {' '}
                - did not match any presentations.
              </p>
              <p>
                Suggestions:
              </p>
              <List>
                <List.Item>
                  <List.Icon name='help' />
                  <List.Content>
                    Make sure all words are spelled correctly.
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='search' />
                  <List.Content>
                    Try different keywords.
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='users' />
                  <List.Content>
                    Try browsing presentations by
                    {' '}
                    <Link to="/speakers"> 
                      speaker
                    </Link>
                    .
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='microphone' />
                  <List.Content>
                    Try browsing presentations by
                    {' '}
                    <Link to="/events"> 
                      event
                    </Link>
                    .
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='tags' />
                  <List.Content>
                    Try browsing presentations by
                    {' '}
                    <Link to="/topics"> 
                      topic
                    </Link>
                    .
                  </List.Content>
                </List.Item>
              </List>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
      </Grid>
    )
  }

  renderResults() {
    if ((!this.props.q.trim().length) && (Object.keys(this.props.presentations).length > 0)) {
      return (
        this.showSearchResults()
      )
    }  else if (!this.props.q.trim().length) {
      return (
        this.showSearchTips()
      )
      } else if ((Object.keys(this.props.presentations).length > 0)) {
      return (
        this.showSearchResults()
      )
    } else {
      return (
        this.showNoSearchResults()
      )
    }
  }

  render() {
    return (
      <>
        { this.renderResults() } 
      </>
    );
  }
}

export default SearchResults;