import React, { Component } from 'react'
import { Grid, Image, Menu } from 'semantic-ui-react'
import './TopMenu.css';

class TopMenu extends Component {

  render() {
    return (
			<Grid className="navGrid">
				<Grid.Row className="navRow">
          <Menu text borderless attached size="massive">
            <Menu.Item
              icon={this.props.icon}
              onClick={this.props.toggleSideBar}
              className="menuToggle"
            />
            <Menu.Item className="navbarLogo">
              <Image src='/ere_pro_logo.png' />
            </Menu.Item>
          </Menu>
				</Grid.Row>
			</Grid>
    )
  }
}

export default TopMenu;