import React from "react";
import { useCheckoutPricing } from '@recurly/react-recurly';

export default function PricingPreview(props) {

  const initialPricingInput = {
    subscriptions: [
      {
        plan: props.plan.code,
      }
    ],
    coupon: props.promotion
  };

  const [{ price, loading }] = useCheckoutPricing(initialPricingInput);
  if (!loading) {
    return (
      <span className="promotion price">
        {(props.plan.trial_length === 0) ? (
          "$" + price.now.total + " "
        ) : (
          "$" + price.next.total + " "
        )}
      </span>
    )
  } else {
    return <span></span>
  };
};
 