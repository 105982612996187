import React from "react";
import history from "../../../history";
import fetchAuth from '../../../fetchAuth.js';
import { isSignedIn } from 'devise-token-client';

export default function WithAuthForRegistered(WrappedComponent){
  class Auth extends React.Component{
    constructor () {
      super()
      this.state = {
        pageLoaded: false,
        isSignedIn: false,
        currentUser: {},
        hostedLoginToken: "",
        subscription: {}
      };
    }
  
    componentDidMount(){
			const isLoggedIn = isSignedIn()
			if (!isLoggedIn) {
				history.push({
					pathname: "/login",
					state: {
						visible: true,
						success: false,
						message: "Please login to access that page."
					}
				})
			} else {
				this.getUser();
			}
    }
      
    getUser() {
      fetchAuth(`/api/get_user_and_account_hosted_token`)
      .then(response => response.json())
      .then(json => {
        if (json) {
          this.setState(
            {
              isSignedIn: isSignedIn(),
              currentUser: json.current_user,
              hostedLoginToken: json.hosted_login_token,
              subscription: json.subscription.data.attributes,
              pageLoaded: true
            }
          )
        }
      })
    }

    render(){
      if (this.state.pageLoaded === false) {
        return null;
      }
      return <WrappedComponent {...this.props} currentUser={this.state.currentUser} hostedLoginToken={this.state.hostedLoginToken} subscription={this.state.subscription} />
    }
  }  
    return Auth
}
