import React from "react";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import App from "./App.js";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "typeface-lato";
import "typeface-roboto";
import "semantic-ui-css/semantic.css";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
      language="en"
      useRecaptchaNet={true}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: "head",
        nonce: undefined,
      }}
    >
      <HelmetProvider>
        <Helmet defaultTitle="ERE Pro" titleTemplate="%s | ERE Pro">
          <html lang="en-US" />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="ERE Pro is the world's largest library of recruiting, sourcing and talent acquisition media."
          />
        </Helmet>
        <App />
      </HelmetProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
