import React from "react";
import { Container, Grid, Header, Icon } from 'semantic-ui-react'
import './Loading.css';

const Loading = () => (
  <Container fluid className="loadingContainer">
  <Grid className="loadingGrid">
    <Grid.Column className="spinner" textAlign='center' verticalAlign='middle'>
      <Icon loading name='asterisk' size='massive' />
      <Header as='h1'>Loading...</Header>
    </Grid.Column>
  </Grid>
  </Container>
);

export default Loading;
