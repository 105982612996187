import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { isSignedIn, persistToken } from 'devise-token-client';
import history from '../../../history';
import putAuth from '../../../putAuth.js';

class ResetPasswordForm extends Component {
  constructor () {
    super()
    this.state = {
      password: '',
      password_confirmation: ''
    };
  }

  fetch(endpoint, fields) {
    const reqObj = {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'

      },
      body: fields
    }
    fetch(endpoint, reqObj)
    .then(response => {
      persistToken(response.headers)
      if(isSignedIn()){
        history.push({
          pathname: "/",
          state: {
            visible: true,
            success: true,
            message: "Email Sent."
          }
        })
      }
      return response.json();
    })
    .then (jsonResponse => {
      if (jsonResponse.success === false) {
        throw new Error(jsonResponse.errors[0])
      }
    })
    .catch(error => {
      this.props.onErrorMessage(error.message)
    })
  }

  handleSubmit(fields, params) {
    const data = JSON.stringify(fields)
    const paramsObject = {
      "token-type": "Bearer",
      "access-token": params["access-token"], 
      client: params.client, 
      expiry: params.expiry, 
      uid: params.uid
    }
		putAuth('/api/auth/password', data, {}, paramsObject)
		.then(response =>{
			if(response.status === 200) {
				history.push({
					pathname: "/",
					state: {
						visible: true,
						success: true,
						message: "Password updated!"
					}
				})
			}
		})
  }

  getField(name, placeholder, type, errormessage, touched) {
    return (
      <>
      <div className={'field' + (errormessage && touched ? ' error' : '')}>
      <Field
          name={name}
          placeholder={placeholder}
          type={type}
        />
      </div>
      <div className="errorDiv mobileErrorDiv">
        {touched && errormessage && <span>{errormessage}</span>}
      </div>
      </>
    )
  }

  render() {
      const params = this.props.params
      return (
      <Formik
        initialValues={{
          password: '',
          password_confirmation: ''
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .min(6, 'Password must be at least 6 characters.')
            .required('Password is required.'),
          password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match.')
            .required('Password confirmation is required.'),
        })}
        onSubmit={fields => {
          this.handleSubmit(fields, params)
        }}
      >
        {({ errors, status, touched }) => (
          <Form className="ui form">
            {this.getField("password", "Password", "password", errors.password, touched.password)}
            {this.getField("password_confirmation", "Password confirmation", "password", errors.password_confirmation, touched.password_confirmation)}
            <Button primary type="submit">
              Reset
            </Button>
          </Form>
        )}
      </Formik>
    )
  }
}

export default ResetPasswordForm;
