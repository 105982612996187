import React, { Component } from 'react';
import { Button, Container, Grid, Header } from 'semantic-ui-react'
import Search from '../Search/Search.js';
import SearchResults from '../SearchResults/SearchResults.js';
import FormatPresentationJson from '../FormatPresentationJson/FormatPresentationJson.js';
import Loading from '../Loading/Loading.js';
import fetchAuth from '../../fetchAuth.js';
import history from "../../history";
import { Helmet } from "react-helmet-async";
import './TopicPage.css';

class TopicPage extends Component {
  constructor () {
    super()
    this.state = {
      pageLoaded: false,
      presentationsLoaded: false,
      currentPage: 1,
      perPage: 12,
      totalPages: null,
      topic: {},
      presentations: [],
      q: "",
      typingTimeout: 0
    };
    this.getData = this.getData.bind(this)
    this.handleQ = this.handleQ.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  componentDidMount () {
    this.getData();
  }

  getData () {
    this.waitingFor = this.state.q
    fetchAuth(`/api/tags/${this.props.match.params.id}?page=${this.state.currentPage}&perpage=${this.state.perPage}&q=${this.state.q}`)
    .then(response => {
      if (response.status === 404) {
        history.push({
          pathname: "/topics",
          state: {
            visible: true,
            success: false,
            message: "We could not find that topic."
          }
        })
        }
      return response.json();
    })
  .then(json => {
      if (json) {
        if (this.state.q === this.waitingFor) {
          this.setState(
            {
              topic: json.topic,
              presentations: FormatPresentationJson(json, "presentations"),
              totalPages: json.pagination.total_pages,
              pageLoaded: true,
              presentationsLoaded: true
            }
          )
      }
      }
    })
  }

  handleQ(event) {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      q: event.target.value,
      typing: false,
      presentationsLoaded: false,
      currentPage: 1,
      typingTimeout: setTimeout(function () {
        self.getData()
      }, 300)
    });
  }

  handlePaginationChange(e, data) {
    this.setState(
      {
        presentationsLoaded: false,
        currentPage: data.activePage
      }, () => this.getData()
    )
  }

  render() {
    const pageLoaded = this.state.pageLoaded
    if (pageLoaded === false) {
      return (
        <Loading />
      )
    }
    return (
      <Container fluid className="mainContentContainer">
        <Helmet>
          <title>
            {this.state.topic.data.attributes.display_name}
          </title>
          <meta name="description" content={`Videos and presentations about recruiting, sourcing, talent acquisition, and ${this.state.topic.data.attributes.display_name}.`} />
          <meta property="og:title" content={`Videos about recruiting & ${this.state.topic.data.attributes.display_name}`} />
          <meta property="og:description" content={`Videos and presentations about recruiting, sourcing, talent acquisition, and ${this.state.topic.data.attributes.display_name}.`} />
        </Helmet>
        <Grid container>
          <Container className="topicPageContainer">
            <Grid centered>
              <Grid.Row className="headerRow">
                <Header textAlign="center">
                  <Header.Subheader className="topicPageSubheader">
                    Presentations about{" "}
                    <Button as="div">
                      {this.state.topic.data.attributes.display_name}
                    </Button>
                  </Header.Subheader>
                </Header>
              </Grid.Row>
            </Grid>
            <Search onChange={this.handleQ} loading={!this.state.presentationsLoaded} pageName="presentations" />
            <SearchResults presentations={this.state.presentations} q={this.state.q} presentationsLoaded={this.state.presentationsLoaded} onPageChange={this.handlePaginationChange} currentPage={this.state.currentPage} totalPages={this.state.totalPages} />
          </Container>
        </Grid>
      </Container>
    );
  }
}

export default TopicPage;
