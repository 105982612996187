import React, { Component } from 'react'
import { Message, Transition } from 'semantic-ui-react'
import './RenewMessage.css';

class RenewMessage extends Component {

  render() {
    return (
      <Transition visible={this.props.visible} animation='scale' duration={500}>
        <Message
          className="renewMessage"
          compact
          warning
          onDismiss={this.props.handleDismiss}
        >
          You only have {this.props.daysLeft} days left of ERE Pro.&nbsp;
          <a href="/chooseplan">
            Renew your subscription now!
          </a>
        </Message>
      </Transition>
    )
  }
}

export default RenewMessage;