import React, { Component } from 'react';
import { Button, Container, Grid, Header, Segment } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import fetchAuth from '../../../fetchAuth.js';
import { Helmet } from "react-helmet-async";
import './ChoosePlan.css';

class ChoosePlan extends Component {
  constructor () {
    super()
    this.state = {
      currentUser: {},
    };
  }

  componentDidMount () {
    this.getCurrentUser();
  }

  getCurrentUser () {
    fetchAuth(`/api/profile`)
    .then(response => response.json())
    .then(json => {
      if (json) {
        this.setState(
          {
            currentUser: json.current_user

          }
        )
      }
    })
  }

  render() {
    return (
      <Container fluid className="mainContentContainer">
        <Helmet>
          <title>
            Choose a new plan 
          </title>
        </Helmet>
        <Grid container>
          <Container fluid className="subscriptionContainer bottomSubscriptionContainer">
            <Container>
              <Grid className="subscriptionGrid">
                <Grid.Row centered className="subscriptionRow">
                  <Grid.Column mobile={16} tablet={10} computer={10} textAlign="center">
                    <div className="separator white"></div>
                    <Header as="h2" className="subscriptionHeader">
                      Membership Change
                      <Header.Subheader className="subscriptionSubHeader">
                        Choose a new plan
                      </Header.Subheader>
                    </Header>
                    <div as="h4" className="subscriptionText">
                      Whether you are renewing your membership or changing your subscription plan, you will not be charged for any time remaining on your current subscription.
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid stackable className="optionsGrid">
                <Grid.Row centered>
                  <Grid.Column mobile={8} tablet={6} computer={5}>
                    <Segment
                      padded="very"
                      as={Link}
                      to={{
                        pathname: "/renew",
                        search: "?plan=quarterly-no-trial",
                      }}
                    >
                      <Grid centered>
                        <Grid.Row>
                          <Header size='medium'>Quarterly Plan</Header>
                        </Grid.Row>
                        <Grid.Row>
                          <Header size='huge'>$89.99</Header>
                        </Grid.Row>
                        <Grid.Row className="dailyPrice">
                          Only $1 / Day
                        </Grid.Row>
                        <Grid.Row>
                        <Button primary className="subscribeButton">Choose this plan</Button>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column only="tablet computer" computer={2}>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={6} computer={5}>
                    <Segment
                      padded="very"
                      as={Link}
                      to={{
                        pathname: "/renew",
                        search: "?plan=annual-no-trial",
                      }}
                    >
                      <Grid centered>
                        <Grid.Row>
                          <Header size='medium'>Annual Plan</Header>
                        </Grid.Row>
                        <Grid.Row>
                          <Header size='huge'>$239.99</Header>
                        </Grid.Row>
                        <Grid.Row className="dailyPrice">
                          Only 66&#162; / Day
                        </Grid.Row>
                        <Grid.Row>
                        <Button primary className="subscribeButton">Choose this plan</Button>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Container>
        </Grid>
      </Container>
    )
  }
}

export default ChoosePlan;