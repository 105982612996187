import React, { Component, Fragment} from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { HashLink } from 'react-router-hash-link';
import { removeToken } from 'devise-token-client';
import history from "../../history";
import './SideMenu.css';

class SideMenu extends Component {
  constructor () {
    super()
    this.state = {
      accountItem: false,
    };
    this.toggleAccountItem = this.toggleAccountItem.bind(this)
  }

  toggleAccountItem() {
    this.setState(
      {
        accountItem: !this.state.accountItem
      }
    ) 
  }

  handleLogout() {
    removeToken()
    history.push({
      pathname: "/",
      state: {
        visible: true,
        success: true,
        message: "Logged out."
      }
    })
  }

  subscriptionLink(activeItem) {
    const hostedLoginToken = this.props.hostedLoginToken
    const subscriptionState = this.props.subscription.state
    const hostedPage = `https://eremedia.recurly.com/account/${this.props.hostedLoginToken}`
    if (hostedLoginToken && (subscriptionState === "active" || subscriptionState === "canceled")) {
      return (
        <Fragment>
          <Menu.Item
            link
            href="/favorites"
            name='favorites'
          >
            <Icon name='star' />
            Favorites
          </Menu.Item>
          <Menu.Item 
            href='/profile'
          >
            <Icon name='user' />
            Account
          </Menu.Item>
          <Menu.Item
            link
            href={hostedPage}
            target="_blank"
            className="subscriptionLink"
          >
            <Icon name='dollar' />
            Subscription
          </Menu.Item>
          <Menu.Item 
            onClick={this.handleLogout}
          >
            <Icon name='log out' />
            Logout
          </Menu.Item>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Menu.Item
            link
            href="/favorites"
            name='favorites'
          >
            <Icon name='star' />
            Favorites
          </Menu.Item>
          <Menu.Item 
            href='/profile'
          >
            <Icon name='user' />
            Account
          </Menu.Item>
          <Menu.Item
            as={HashLink}
            to="/join#subscribe"
            name='subscribe'
            active={['/join', '/register', '/subscribe'].includes(activeItem)}
            className="subscribeLink"
          >
            <Icon name='dollar' />
            Subscribe
          </Menu.Item>
          <Menu.Item 
            onClick={this.handleLogout}
          >
            <Icon name='log out' />
            Logout
          </Menu.Item>
        </Fragment>
        )
    }
  }

  render() {
		const activeItem = this.props.activeItem
    return (
			<Menu
        pointing
				fluid
				vertical
				icon="labeled"
				className="ereBlue mainMenu"
				inverted
			>
        <Menu.Item className="menuSpacer">
        </Menu.Item>
				<Menu.Item
					link
					href="/"
					name='presentations'
					active={activeItem === '/'}
				>
					<Icon name='home' />
					Home
				</Menu.Item>
				<Menu.Item
					link
					href="/search"
					name='search'
					active={activeItem === '/search'}
				>
					<Icon name='search' />
					Search
				</Menu.Item>
				<Menu.Item
					link
					href="/speakers"
					name='speakers'
					active={['/speakers', '/speakers/:id'].includes(activeItem)}
				>
					<Icon name='users' />
					Speakers
				</Menu.Item>
				<Menu.Item
					link
					href="/events"
					name='events'
					active={['/events', '/events/:id'].includes(activeItem)}
				>
					<Icon name='microphone' />
					Events
				</Menu.Item>
				<Menu.Item
					link
					href="/topics"
					name='topics'
					active={['/topics', '/topics/:id'].includes(activeItem)}
				>
					<Icon name='tags' />
					Topics
				</Menu.Item>

        {(Object.keys(this.props.currentUser).length > 0) ? (
            this.subscriptionLink(activeItem)
        ) : (
          <Fragment>
            <Menu.Item
              link
              href="/login"
              name='login'
            >
              <Icon name='sign-in' />
              Login
            </Menu.Item>
            <Menu.Item
              as={HashLink}
              to="/join#subscribe"
              name='subscribe'
              active={['/join', '/register', '/subscribe'].includes(activeItem)}
              className="subscribeLink"
              >
              <Icon name='dollar' />
              Subscribe
            </Menu.Item>
          </Fragment>
        )}
			</Menu>
    );
  }
}

export default SideMenu;