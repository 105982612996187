import React, { Component } from 'react';
import { Container, Grid, Header, Sticky } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import history from "../../history";
import SubscribeSteps from '../SubscribeSteps/SubscribeSteps.js';
import { Helmet } from "react-helmet-async";
import './Confirm.css';

class Confirm extends Component {
  constructor () {
    super()
    this.state = {
      totalTime: 10000,
      remainingTime: 10,
      error: false,
      errorMessage: "",
      pageLoaded: false,
    };
  }

  componentDidMount() {
    this.countdown()
    this.timedRedirect()
  }
  
  countdown() {
    setInterval(() => {
      this.setState(prevState => ({remainingTime: prevState.remainingTime - 1}));
    }, 1000)
  }

  timedRedirect() {
    setTimeout(() => { 
      history.push('/');
    }, this.state.totalTime)
  }
  
  render() {
    const pageLoaded = this.state.pageLoaded;
    if (pageLoaded === true) {
      return null
    }

    return (
      <>
        <Helmet>
          <title>
            Subscription Confirmation 
          </title>
        </Helmet>
        <Sticky className="subscribeStepsSticky">
          <SubscribeSteps />
        </Sticky>
        <Container fluid className="confirmPageMainContentContainer">
          <Grid stackable className="confirmPageMainContentGrid">
            <Grid.Row reversed="tablet computer" className="registerPageMainContentRow">
              <Grid.Column mobile={16} tablet={8} computer={8} className="rightColumn">

                <Grid verticalAlign='middle' textAlign='center' className="authGrid">
                  <Grid.Row className="confirmHeaderRow">
                    <Grid.Column width="12" textAlign="left">
                      <div className="separator">
                      </div>
                      <Header as="h2" className="confirmHeader">
                        Thank you
                        <Header.Subheader className="confirmSubHeader">
                          for subscribing to ERE Pro
                        </Header.Subheader>
                      </Header>
                      <div className="confirmText">
                        You will be redirected in {this.state.remainingTime} seconds or you can click <Link to={"/"}>here</Link>.
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8} className="leftColumn">
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>         
      </>
    )
  }
}

export default Confirm;