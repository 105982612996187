import React, { Component } from 'react'
import { Grid, Item, List, Placeholder, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import PaginationBar from '../PaginationBar/PaginationBar.js'; 
import './EventItems.css';

class EventItems extends Component {

  sortEvents(events, columns) {
    let sortedEvents = [], counter, numberOfEvents
    for (counter=0,numberOfEvents=events.length; counter<numberOfEvents; counter+=columns) {
        sortedEvents.push(events.slice(counter,counter+columns));
    }
    return sortedEvents
  }

  getHighlightedText(text, q) {
    if (q) {
      const parts = text.split(new RegExp(`(${q})`, 'gi'));
      return parts.map(part => {
        const uniqueId = uuid();
        if (part.toLowerCase() === q.toLowerCase()) {
          return (
            <span className="highlight" key={uniqueId}>{part}</span>
          )
        } else {
          return (
          <span key={uniqueId}>{part}</span>
          )
        }
      })
    } else {
      return text;
    }
  }

  hasEvents() {
    if (this.props.events.length > 0) {
      const numberOfColumns = 3
      const sortedEvents = this.sortEvents(this.props.events, numberOfColumns)
      return (
        <Grid stackable centered columns={numberOfColumns}>
          <Grid.Row>
            <PaginationBar 
              position="topPagination"
              page={this.props.currentPage} 
              totalpages={this.props.totalPages} 
              onPageChange={this.props.onPageChange}
            />
          </Grid.Row>
          {sortedEvents.map(row => {
            const uniqueIdRow = uuid();
            return (
              <Grid.Row key={uniqueIdRow}>
                {row.map(event => {
                  const uniqueId = uuid();
                  let formattedDate;
                  if (typeof(event.attributes.start_date) === "string") {
                    formattedDate = new Intl.DateTimeFormat('en-US', {
                      year: 'numeric',
                      month: 'short'
                    }).format(Date.parse(event.attributes.start_date));
                  } else {
                    formattedDate = null;
                  }
                  return (
                    <Grid.Column key={uniqueId} className="eventItemsColumn">
                      {this.props.eventsLoaded ?  (
                        <Item.Group link href={`/events/${event.attributes.slug}`}>
                          <Item className="eventItem">
                            <Item.Image circular size='tiny' className="eventImage" src={event.brand.attributes.logo} />
                            <Item.Content verticalAlign="middle">
                              <Item.Header className="eventLink">
                                {this.getHighlightedText(event.attributes.name, this.props.q)}
                              </Item.Header>
                              <Item.Meta className="eventDate">
                                {formattedDate}
                              </Item.Meta>
                            </Item.Content>
                          </Item>
                        </Item.Group>  
                      ) : (
                        <Placeholder>
                        <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Header>
                      </Placeholder>
                    )}
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            )
          })}
          <Grid.Row>
            <PaginationBar 
              position="bottomPagination"
              page={this.props.currentPage} 
              totalpages={this.props.totalPages} 
              onPageChange={this.props.onPageChange}
            />
          </Grid.Row>
        </Grid>
      )
    } else {
      return (
        <Grid stackable centered>
          <Grid.Row>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="16">
              <Segment secondary size="large">
                <p>
                  Your search - 
                  {' '}
                  <strong>{
                    this.props.q}
                  </strong>
                  {' '}
                  - did not match any events.
                </p>
                <p>
                  Suggestions:
                </p>
                <List>
                  <List.Item>
                    <List.Icon name='help' />
                    <List.Content>
                      Make sure all words are spelled correctly.
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='search' />
                    <List.Content>
                      Try different keywords.
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='users' />
                    <List.Content>
                      Try browsing presentations by
                      {' '}
                      <Link to="/speakers"> 
                        speaker
                      </Link>
                      .
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='microphone' />
                    <List.Content>
                      Try browsing presentations by
                      {' '}
                      <Link to="/events"> 
                        event
                      </Link>
                      .
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='tags' />
                    <List.Content>
                      Try browsing presentations by
                      {' '}
                      <Link to="/topics"> 
                        topic
                      </Link>
                      .
                    </List.Content>
                  </List.Item>
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          </Grid.Row>
        </Grid>
      )
    }
  }

  render() {
    const results = this.hasEvents()
    return (
      <>
        {results} 
      </>
    );

  }
}
    
export default EventItems;