import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import Search from '../Search/Search.js';
import EventItems from '../EventItems/EventItems.js';
import Loading from '../Loading/Loading.js';
import fetchAuth from '../../fetchAuth.js';
import { Helmet } from "react-helmet-async";

class Events extends Component {
  constructor () {
    super()
    this.state = {
      pageLoaded: false,
      eventsLoaded: false,
      currentPage: 1,
      perPage: 18,
      totalPages: null,
      events: [],
      q: "",
      typingTimeout: 0
    };
    this.getData = this.getData.bind(this)
    this.handleQ = this.handleQ.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  componentDidMount () {
    this.getData();
  }

  getData () {
    this.waitingFor = this.state.q
    fetchAuth(`/api/events?page=${this.state.currentPage}&perpage=${this.state.perPage}&q=${this.state.q}`)
      .then(response => response.json())
      .then(json => {
        if (json) {
          if (this.state.q === this.waitingFor) {
            json.events.data.forEach(e => {
              e.brand = json.events.included.find(obj => {
                return (obj.type === e.relationships.brand.data.type && obj.id === e.relationships.brand.data.id)
              })
            })
            this.setState(
              {
                events: json.events.data,
                totalPages: json.pagination.total_pages,
                pageLoaded: true,
                eventsLoaded: true
              }
            )
        }
        }
      })
  }

  handleQ(event) {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      q: event.target.value,
      typing: false,
      eventsLoaded: false,
      currentPage: 1,
      typingTimeout: setTimeout(function () {
        self.getData()
      }, 300)
    });
  }

  handlePaginationChange(e, data) {
    this.setState(
      {
        eventsLoaded: false,
        currentPage: data.activePage
      }, () => this.getData()
    )
  }

  render() {
    const pageLoaded = this.state.pageLoaded
    if (pageLoaded === false) {
      return (
        <Loading />
      )
    }
    return (
      <Container fluid className="mainContentContainer">
        <Helmet>
          <title>
            Events 
          </title>
        </Helmet>
        <Grid container>
          <Container>
            <Search onChange={this.handleQ} loading={!this.state.eventsLoaded} pageName="events" />
            <EventItems events={this.state.events} q={this.state.q} eventsLoaded={this.state.eventsLoaded} onPageChange={this.handlePaginationChange} currentPage={this.state.currentPage} totalPages={this.state.totalPages} pageLoaded={this.state.pageLoaded} />
          </Container>
        </Grid>
      </Container>
    );
  }
}

export default Events;
