import React from 'react';
import { Grid } from 'semantic-ui-react'
import DisplayCard from '../DisplayCard/DisplayCard.js'; 
import { v4 as uuid } from 'uuid';

class CellGrid extends React.Component {

  sortPresentations(presentations, columns) {
    let sortedPresentations = [], counter, numberOfPresentations
    for (counter=0,numberOfPresentations=presentations.length; counter<numberOfPresentations; counter+=columns) {
      sortedPresentations.push(presentations.slice(counter,counter+columns));
    }
    return sortedPresentations
  }

  makeRow(row) {
    const uniqueId = uuid();
    return (
      <Grid.Row stretched key={`row-${uniqueId}`}>
        {row.map(column => {
          const index = row.indexOf(column)
          return this.makeColumn(column, index)
        })}
      </Grid.Row>
    )
  }

  makeColumn(column, index) {
    const uniqueId = uuid();
    return (
      <Grid.Column key={`column-${uniqueId}`}>
        <DisplayCard key={`card-${uniqueId}`} presentation={column} q={this.props.q} presentationsLoaded={this.props.presentationsLoaded} compact={this.props.compact} />
      </Grid.Column>
    )
  }

  render() {
    let numberOfColumns = 4
    if (this.props.presentations.length === 1) {
      numberOfColumns = 3
    }
    const sortedPresentations = this.sortPresentations(this.props.presentations, numberOfColumns)
    return (
      <Grid stackable doubling centered columns={numberOfColumns} className="cellGrid">
        {sortedPresentations.map(row => {
          return this.makeRow(row)
        })}
      </Grid>
    );
  }
}
    
export default CellGrid;