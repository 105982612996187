import React, { Component } from 'react';
import { Button, Grid, Header, Message, Transition } from 'semantic-ui-react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import history from '../../../history';
import putAuth from '../../../putAuth.js';
import './PasswordForm.css';

class PasswordForm extends Component {
  constructor () {
    super()
    this.state = {
      submitted: false
    };
  }

  handleSubmit(fields) {
    this.setState({
      submitted: true,
    })
    const data = JSON.stringify(fields)
		putAuth('/api/auth/password', data)
    .then(response => {
			if(response.status === 200) {
				history.push({
					pathname: "/",
					state: {
						visible: true,
						success: true,
						message: "Password updated!"
					}
				})
			}
      return response.json();
    })
    .then(jsonResponse => {
      if (jsonResponse.success === false) {
        this.setState({
          submitted: false,
        })    
        throw new Error(jsonResponse.errors.full_messages[0])
      }
    })
    .catch(error => {
      this.setState({
        submitted: false,
      })    
    this.props.onErrorMessage(error.message) 
    })
  }

  getField(name, placeholder, type, errormessage, touched) {
    return (
      <>
        <div className={'field' + (errormessage && touched ? ' error' : '')}>
          <Field
            name={name}
            placeholder={placeholder}
            type={type}
          />
        </div>
        <div className="errorDiv mobileErrorDiv">
          {touched && errormessage && <span>{errormessage}</span>}
        </div>
      </>
    )
  }

  render() {
    const error = this.props.error
    const errorMessage = this.props.errorMessage
    return (
      <Grid verticalAlign='middle' textAlign='center' className={this.props.showForm ? ("profileGrid authGrid") : ("noDisplay profileGrid authGrid")}>
        <Grid.Row className="renewHeaderRow">
          <Grid.Column width="12" textAlign="left">
            <div className="separator">
            </div>
            <Header as="h2" className="renewHeader">
              Change Password
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="renewErrorRow">     
          <Grid.Column width="12" textAlign="left">
            <Transition visible={error} animation='scale' duration={{show: 500, hide: 0}}>
              <Message error header={errorMessage} />
            </Transition>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="renewFormRow">
          <Grid.Column width="12" textAlign="left">
            <Formik
              initialValues={{
                current_password: '',
                password: '',
                password_confirmation: ''
                  }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .min(6, 'Password must be at least 6 characters.')
                  .required('Password is required.'),
                password_confirmation: Yup.string()
                  .oneOf([Yup.ref('password'), null], 'Passwords must match.')
                  .required('Password confirmation is required.'),
              })}
              onSubmit={fields => {
                this.handleSubmit(fields)
              }}
            >
              {({ errors, status, touched }) => (
                <Form key="profileForm" noValidate className="ui form">
                  {this.getField("current_password", "Current password", "password", errors.current_password, touched.current_password)}
                  {this.getField("password", "New password", "password", errors.password, touched.password)}
                  {this.getField("password_confirmation", "New password confirmation", "password", errors.password_confirmation, touched.password_confirmation)}
                  <div>
                    <Button primary type="submit" className={this.state.submitted ? ("disabled") : ("")}>
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default PasswordForm;
