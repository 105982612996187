import React from "react";
import history from "../../../history";
import queryString from 'query-string';
import fetchAuth from '../../../fetchAuth.js';
import { isSignedIn } from 'devise-token-client';

export default function WithAuthForDevise(WrappedComponent){
  class Auth extends React.Component{
    constructor () {
      super()
      this.state = {
        pageLoaded: false,
        isSignedIn: false,
        currentUser: {},
        hostedLoginToken: "",
        subscription: {}
      };
    }
  
    componentDidMount(){
			const isLoggedIn = isSignedIn()
			if (isLoggedIn) {
        this.getUser();
			} else {
				this.setState(
					{
						isSignedIn: isLoggedIn,
						pageLoaded: true,
					}
				)
      }
    }
      
      getUser() {
        const search = queryString.parse(this.props.location.search)
        fetchAuth(`/api/get_user_and_account_hosted_token`)
        .then(response => response.json())
        .then(json => {
          if (json) {
            this.setState(
              {
								isSignedIn: isSignedIn(),
								currentUser: json.current_user,
                hostedLoginToken: json.hosted_login_token,
                subscription: json.subscription.data.attributes
							}, () => {
								if (search.plan && !(this.state.subscription.state === "active" || this.state.subscription.state === "canceled")) {
                  history.push({
                    pathname: "/subscribe",
                    search: `?plan=${search.plan}`,
									})
								} else if (this.state.isSignedIn === true && this.props.computedMatch.path !== "/profile") {
                  history.push({
                    pathname: "/",
                    state: {
                      visible: true,
                      success: false,
                      message: "You are already logged in."
                    }
                  })
                } else {
                  this.setState(
                    {
                      isSignedIn: isSignedIn(),
                      pageLoaded: true
                    }
                  )
                }
							}
            )
          }
        })
      }

    render(){
      if (this.state.pageLoaded === false) {
        return null;
      }
      return <WrappedComponent {...this.props} currentUser={this.state.currentUser} hostedLoginToken={this.state.hostedLoginToken} subscriptione={this.state.subscription} />
    }
  }  
    return Auth
}
