import React, { Component, Fragment } from 'react';
import { Button, Container, Divider, Grid, Header, Icon, Image, Message, Segment, Transition } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import DOMPurify from "dompurify";
import Vimeo from '@u-wave/react-vimeo';
import FormatPresentationJson from '../FormatPresentationJson/FormatPresentationJson.js';
import Loading from '../Loading/Loading.js';
import PdfViewer from '../PdfViewer/PdfViewer.js';
import { v4 as uuid } from 'uuid';
import fetchAuth from '../../fetchAuth.js';
import history from "../../history";
import { Helmet } from "react-helmet-async";
import postAuth from '../../postAuth.js';
import './PresentationPage.css';

class PresentationPage extends Component {
  constructor() {
    super()
    this.state = {
      pageLoaded: false,
      showPdf: false,
      presentation: {},
      speakers: [],
      hasPdf: false,
      hasSponsors: false,
      favorite: false,
      notRecentlyReported: true,
      startTime: 0,
      userPresentation: {}
    };
    this.getData = this.getData.bind(this)
    this.togglePdf = this.togglePdf.bind(this)
    this.toggleFavorite = this.toggleFavorite.bind(this)
    this.reportTime = this.reportTime.bind(this)
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    fetchAuth(`/api/presentations/${this.props.match.params.id}`)
      .then(response => {
        if (response.status === 404) {
          history.push({
            pathname: "/",
            state: {
              visible: true,
              success: false,
              message: "We could not find that presentation."
            }
          })
          }
        return response.json();
      })
      .then(json => {
        if (json) {
          const presentation = FormatPresentationJson(json, "presentation")[0]
          const hasPdf = presentation.attributes.pdf ? (true) : (false)
          const hasSponsors = presentation.sponsors.length > 0
          const startTime = json["time"]["data"][0] ? (json["time"]["data"][0].attributes.current_seconds) : (0)
          const favorite = json["favorite"]
          const userPresentation = json["time"]["data"][0] ? (json["time"]["data"][0].id) : ({})
          this.setState(
            {
              presentation: presentation,
              pageLoaded: true,
              hasPdf: hasPdf,
              hasSponsors: hasSponsors,
              startTime: startTime,
              favorite: favorite,
              userPresentation: userPresentation
            }
          )
        }
      })
  }

  getSpeakers(presentation) {
    let speakers = []
    presentation.presentation_speaker.forEach(ps => {
      const speaker = presentation.speakers.find(speaker => speaker.id === ps.relationships.speaker.data.id);
      speakers.push({
        full_name: speaker.attributes.full_name, 
        slug: speaker.attributes.slug, 
        bio: speaker.attributes.bio, 
        image: speaker.attributes.image,
        twitter: speaker.attributes.twitter,
        linkedin: speaker.attributes.linkedin,
        pdf: speaker.attributes.pdf
      });
    })
    return speakers
  }

  togglePdf() {
    this.setState(prevState => ({
      showPdf: !prevState.showPdf
    }));
  }

  toggleFavorite() {
    postAuth('/api/toggle_favorite', JSON.stringify({presentation: this.state.presentation.id}))
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json) {
        this.setState(prevState => ({
          favorite: json["favorite"]
        }));
      }
    })
  }

  getDate(dateString) {
    const date = new Date(dateString).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC' 
    });
    return date
  }

  addSpeakerLink(text, id) {
    return <a href={`/speakers/${id}`}>{text}</a>
  }

  getSpeakerNames(speakers) {
    let nameBlock = null
    nameBlock = speakers.map(speaker => {
      const uniqueId = uuid();
      return (
        <div className="speakerMeta" key={`speakerName-${uniqueId}`}>
          {this.addSpeakerLink(speaker.name, speaker.slug)}
        </div>
      )
    })
    return nameBlock;
  }

  reportTime(currentTime) {
    if (this.state.notRecentlyReported) {
      this.setState(
        {
          notRecentlyReported: false
        }
      )
      setTimeout(function(){
        this.setState(
          {
            notRecentlyReported:true
          }
        )
      }.bind(this),5000);  // wait 5 seconds, then reset to false
      currentTime.presentation = this.state.presentation.id
      postAuth('/api/report_time', JSON.stringify(currentTime))
    }
  }

  getTopics(topics) {
    let topicsBlock = null
    if (topics.length >= 1) {
      topicsBlock = topics.map(topic => {
        const uniqueId = uuid();
        return (
          <span key={`topic-${uniqueId}`}>
            <a href={`/topics/${topic}`}  className="topicName">
              {topic}
            </a>
            {' '}
          </span>
        )
      })
    }
    return topicsBlock
  }

  render() {
    const pageLoaded = this.state.pageLoaded
    if (pageLoaded === false) {
      return (
        <Loading />
      )
    }
    const presentation = this.state.presentation
    const speakers = this.getSpeakers(presentation)
    const presentationDate = this.getDate(presentation.attributes.date)
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long'
    }).format(Date.parse(presentation.event.attributes.start_date));
    return (
      <>
        <Helmet>
          <title>
            {presentation.attributes.title} 
          </title>
          <meta name="description" content={presentation.attributes.short_description} />
          <meta property="og:title" content={presentation.attributes.title} />
          <meta property="og:description" content={presentation.attributes.short_description} />
          <meta property="og:type" content="video.movie" />
        </Helmet>
        <Container fluid className="presentationPageVideoContainer">
          <Vimeo
            className="videoPlayer"
            video={presentation.attributes.video_id}
            showPortrait
            onTimeUpdate={this.reportTime}
            start={this.state.startTime}
          />
        </Container>
        <Grid container className="presentationPageMainContainer">
          <Transition visible={this.state.showPdf} animation="slide down" duration={500}>
            <Container className="presentationPagePdfContainer" >
              <PdfViewer pdfUrl={this.state.presentation.attributes.pdf} />
            </Container>
          </Transition>
          <Container className="presentationPageContainer">
            <Grid stackable>
              <Grid.Row reversed="tablet computer">
              <Grid.Column mobile={6} tablet={5} computer={5} textAlign="center">
                  {this.state.hasPdf ? (
                    <div className="pdfButton">
                      <Button basic size="huge" color='blue' onClick={this.togglePdf}>
                        <Icon name='file pdf' />
                        {this.state.showPdf ? ("Hide Slides") : ("View Slides")}
                      </Button>
                    </div>
                  ) : (
                    null
                  )}
                </Grid.Column>
                <Grid.Column only="tablet computer" tablet={1} computer={1}>
                </Grid.Column>
                <Grid.Column mobile={10} tablet={10} computer={10}>
                  <Header className="presentationTitle">
                    {presentation.attributes.title}
                  </Header>
                  <div className="presentationDate">
                    {presentationDate}
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={10} tablet={10} computer={10}>
                  <div className="presentationDescription" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(presentation.attributes.description, { ALLOWED_TAGS: ["p", "ul", "li"] })}} />
                  <div>
                    {this.getTopics(presentation.attributes.tag_list)}
                  </div>
                </Grid.Column>
                <Grid.Column only="tablet computer" tablet={1} computer={1}>
                </Grid.Column>
                <Grid.Column mobile={6} tablet={5} computer={5} textAlign="center">
                  {this.state.hasSponsors ? (
                    presentation.sponsors.map(sponsor => {
                      const uniqueId = uuid();
                      return (
                        <Message color="blue" key={`sponsor-${uniqueId}`}>
                          <a className="presentationSponsorLink" href={sponsor.attributes.url} target="_blank" rel="noopener noreferrer">
                            {`Sponsored by ${sponsor.attributes.name}`}
                          </a>
                        </Message>
                      )
                    })
                  ) : (
                    null
                  )}
                  <Segment>
                    <div className="favoriteHeader">
                      Favorite
                    </div>
                    <div className="favoriteButtons">
                      <Button
                        icon
                        className="favoriteButton"
                        onClick={this.toggleFavorite}
                      >
                        {this.state.favorite ? (
                          <Icon name="star" size="big" />
                        ) : (
                          <Icon name="star outline" size="big" />
                        )}
                      </Button>
                    </div>
                  </Segment>
                  <Segment>
                    <div className="shareHeader">
                      Share the knowledge
                    </div>
                    <div className="shareButtons">
                      <Button
                        compact
                        icon
                        target="_blank"
                        rel="noopener noreferrer"
                        className="twitterButton"
                        as="a"
                        href={`https://twitter.com/intent/tweet?text=I'm%20watching%20${presentation.attributes.title}%20on%20ERE%20Pro!&url=${window.location.href}&hashtags=erepro,recruiting,talentacquisition`}
                      >
                        <Icon name="twitter square" size="big" />
                      </Button>

                      <Button
                        compact
                        icon
                        target="_blank"
                        rel="noopener noreferrer"
                        className="facebookButton"
                        as="a"
                        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                      >
                        <Icon name="facebook" size="big" />
                      </Button>

                      <Button
                        compact
                        icon
                        target="_blank"
                        rel="noopener noreferrer"
                        className="linkedinButton"
                        as="a"
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=https://pro.eremedia.com/&title=${presentation.attributes.title}`}
                      >
                        <Icon name="linkedin" size="big" />
                      </Button>
                    </div>
                  </Segment>
                </Grid.Column>
              </Grid.Row>              
              <Grid.Row className="aboutTheSpeakerRow">
                <Grid.Column>
                  <div className="aboutTheSpeakerHeader">
                    About the speaker{Object.keys(speakers).length > 1 ? ("s") : (null)}
                  </div>
                </Grid.Column>
              </Grid.Row>
              {speakers.map (speaker => {
                const hasTwitter = (typeof speaker.twitter === "string" && speaker.twitter.length > 0)
                const hasLinkedin = (typeof speaker.linkedin === "string" && speaker.linkedin.length > 0)
                const uniqueId = uuid();
                return (
                  <Fragment key={`speaker-${uniqueId}`}>
                    <Grid.Row className="presentationSpeakerHeaderRow">
                      <Grid.Column mobile={16} tablet={4} computer={3} widescreen={3} className="speakerHeaderImageColumn">
                        <Image size="small" circular src={speaker.image} className="speakerHeaderImage"  verticalAlign="middle" as="a" href={`/speakers/${speaker.slug}`} /> 
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={11} computer={12} widescreen={10} verticalAlign="middle">
                        <Grid.Row className="speakerNameRow">
                          <Link to={`/speakers/${speaker.slug}`} className="speakerName">
                            {speaker.full_name} 
                          </Link>                  
                        </Grid.Row>
                        <Grid.Row className="bioRow">
                          <div className="bio">
                            {speaker.bio}
                          </div>
                        </Grid.Row>
                        { (hasTwitter || hasLinkedin) ? (
                          <Grid.Row className="speakerButtonRow">
                            { hasTwitter ? (
                              <Button
                              compact
                              icon
                              target="_blank"
                              rel="noopener noreferrer"
                              className="twitterButton"
                              as="a"
                              href={speaker.twitter}
                            >
                              <Icon name="twitter square" size="big" />
                            </Button>
                        ) : (
                              null
                            )}
                            { hasLinkedin ? (
                            <Button
                              compact
                              icon
                              target="_blank"
                              rel="noopener noreferrer"
                              className="linkedinButton"
                              as="a"
                              href={speaker.linkedin}
                            >
                              <Icon name="linkedin" size="big" />
                            </Button>
                          ) : (
                              null
                            )}
                          </Grid.Row>
                        ) : (
                          null
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Divider />
                  </Fragment>
                )
              })}
              <Grid.Row className="originallyPresentedAtRow">
                <Grid.Column>
                  <div className="originallyPresentedAtHeader">
                    Originally presented at
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="presentationEventHeaderRow">
                <Grid.Column mobile={16} tablet={4} computer={3} className="eventHeaderImageColumn">
                  <Image size="small" src={presentation.brand.attributes.logo} className="brandLogo" verticalAlign="middle" as="a" href={`/events/${presentation.event.attributes.slug}`} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={12} computer={13} verticalAlign="middle">
                  <Link to={`/events/${presentation.event.attributes.slug}`} className="eventName">
                    {presentation.event.attributes.name} 
                  </Link>                  
                  <div className="eventDate">
                    {formattedDate}
                  </div>
                </Grid.Column>
              </Grid.Row>

            </Grid>
          </Container>
        </Grid>
      </>
    );
  }
}

export default PresentationPage;
