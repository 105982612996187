import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import Search from '../Search/Search.js';
import SpeakerItems from '../SpeakerItems/SpeakerItems.js';
import Loading from '../Loading/Loading.js';
import fetchAuth from '../../fetchAuth.js';
import { Helmet } from "react-helmet-async";

class Speakers extends Component {
  constructor () {
    super()
    this.state = {
      pageLoaded: false,
      speakersLoaded: false,
      currentPage: 1,
      perPage: 18,
      totalPages: null,
      speakers: [],
      q: "",
      typingTimeout: 0
    };
    this.getData = this.getData.bind(this)
    this.handleQ = this.handleQ.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  componentDidMount () {
    this.getData();
  }

  getData() {
    this.waitingFor = this.state.q
    fetchAuth(`/api/speakers?page=${this.state.currentPage}&perpage=${this.state.perPage}&q=${this.state.q}`)
    .then(response => response.json())
    .then(json => {
      if (json) {
        if (this.state.q === this.waitingFor) {
          this.setState(
            {
              speakers: json.speakers.data,
              totalPages: json.pagination.total_pages,
              pageLoaded: true,
              speakersLoaded: true
            }
          )
      }
      }
    })
  }
  
  handleQ(event) {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      q: event.target.value,
      typing: false,
      speakersLoaded: false,
      currentPage: 1,
      typingTimeout: setTimeout(function () {
        self.getData()
      }, 300)
    });
  }

  handlePaginationChange(e, data) {
    this.setState(
      {
        speakersLoaded: false,
        currentPage: data.activePage
      }, () => this.getData()
    )
  }
  
  render() {
    const pageLoaded = this.state.pageLoaded
    if (pageLoaded === false) {
      return (
        <Loading />
      )
    }
    return (
      <Container fluid className="mainContentContainer">
        <Helmet>
          <title>
            Speakers
          </title>
        </Helmet>
        <Grid container>
          <Container>
            <Search onChange={this.handleQ} loading={!this.state.speakersLoaded} pageName="speakers" />
            <SpeakerItems speakers={this.state.speakers} q={this.state.q} speakersLoaded={this.state.speakersLoaded} onPageChange={this.handlePaginationChange} currentPage={this.state.currentPage} totalPages={this.state.totalPages} pageLoaded={this.state.pageLoaded} />
          </Container>
        </Grid>
      </Container>
    );
  }
}

export default Speakers;
