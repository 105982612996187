import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import SearchResults from '../SearchResults/SearchResults.js';
import FormatPresentationJson from '../FormatPresentationJson/FormatPresentationJson.js';
import Loading from '../Loading/Loading.js';
import Search from '../Search/Search.js';
import fetchAuth from '../../fetchAuth.js';
import { Helmet } from "react-helmet-async";
import './SearchPage.css';

class SearchPage extends Component {
  constructor () {
    super()
    this.state = {
      pageLoaded: false,
      presentationsLoaded: false,
      currentPage: 1,
      perPage: 12,
      totalPages: null,
      presentations: [],
      q: "",
      typingTimeout: 0
      };
    this.getData = this.getData.bind(this)
    this.handleQ = this.handleQ.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  componentDidMount () {
    this.getData();
  }

  getData () {
    this.waitingFor = this.state.q
    fetchAuth(`/api/presentations?page=${this.state.currentPage}&perpage=${this.state.perPage}&q=${this.state.q}`)
    .then(response => response.json())
    .then(json => {
      if (json) {
        if ((this.state.q === this.waitingFor) && (Object.keys(json.presentations).length > 0) && (Object.keys(json.presentations.data).length > 0)) {
          this.setState(
            {
              presentations: FormatPresentationJson(json, "presentations"),
              totalPages: json.pagination.total_pages,
              pageLoaded: true,
              presentationsLoaded: true
            }
          )
        } else {
          this.setState(
            {
              presentations: [],
              totalPages: null,
              pageLoaded: true,
              presentationsLoaded: true
            }
          )
        }
      }
    })
  }

  handleQ(event) {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      q: event.target.value,
      typing: false,
      presentationsLoaded: false,
      currentPage: 1,
      typingTimeout: setTimeout(function () {
        self.getData()
      }, 300)
    });
  }

  handlePaginationChange(e, data) {
    this.setState(
      {
        presentationsLoaded: false,
        currentPage: data.activePage
      }, () => this.getData()
    )
  }

  render() {
    const pageLoaded = this.state.pageLoaded
    if (pageLoaded === false) {
      return (
        <Loading />
      )
    }
    return (
      <Container fluid className="mainContentContainer">
        <Helmet>
          <title>
            Search
          </title>
        </Helmet>
        <Grid container>
          <Container className="SearchPageContainer">
            <Search onChange={this.handleQ} loading={!this.state.presentationsLoaded} pageName="" />
            {this.state.presentationsLoaded ? (<SearchResults presentations={this.state.presentations} q={this.state.q} presentationsLoaded={this.state.presentationsLoaded} onPageChange={this.handlePaginationChange} currentPage={this.state.currentPage} totalPages={this.state.totalPages} pageLoaded={this.state.pageLoaded} />) : (null)}
          </Container>
        </Grid>
      </Container>
    );
  }
}

export default SearchPage;
