import React, { Component } from 'react'
import { Message, Transition } from 'semantic-ui-react'
import './Flash.css';

class Flash extends Component {
  constructor() {
    super()
    this.state = {
    };
  }

  render() {
    return (
      <Transition visible={this.props.visible} animation='scale' duration={500}>
        <Message
          className="flashMessage"
          compact
          negative={!this.props.success}
          success={this.props.success}
          header={this.props.message}
        />
      </Transition>
    )
  }
}

export default Flash;