import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'
import { Formik, Form, Field } from 'formik';
import history from '../../../history';

class ForgotPasswordForm extends Component {
  constructor () {
    super()
    this.state = {
      email: "",
      password: ""
    };
  }

  fetch(endpoint, fields) {
    const reqObj = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: fields
    }
    fetch(endpoint, reqObj)
    .then(response => {
      if(response.status === 200) {
        history.push({
          pathname: "/",
          state: {
            visible: true,
            success: true,
            message: "Email Sent."
          }
        })
      }
      return response.json();
    })
    .then (jsonResponse => {
      if (jsonResponse.success === false) {
        throw new Error(jsonResponse.errors[0])
      }
    })
    .catch(error => {
      this.props.onErrorMessage(error.message)
    })
  }

  handleSubmit(fields) {
    const data = JSON.stringify(fields);
    this.fetch('/api/auth/password', data);
  }

  getField(name, placeholder, type) {
    return (
      <>
        <div className='field'>
          <Field
            name={name}
            placeholder={placeholder}
            type={type} />
        </div>
        <div className="errorDiv mobileErrorDiv">
        </div>
      </>
    )
  }

  render() {
    return (
      <Formik
        initialValues={{
          email: ''
        }}
        onSubmit={fields => {
          this.handleSubmit(fields)
        }}
      >
        {({ errors, status, touched }) => (
          <Form className="ui form">
            {this.getField("email", "Email", "email")}
            <Button primary type="submit">
              Email me
            </Button>
          </Form>
        )}
      </Formik>
    )
  }
}

export default ForgotPasswordForm;
