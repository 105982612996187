import React, { Component } from 'react';
import { Container, Grid, Tab } from 'semantic-ui-react'
import CellGrid from '../CellGrid/CellGrid.js';
import FormatPresentationJson from '../FormatPresentationJson/FormatPresentationJson.js';
import Loading from '../Loading/Loading.js';
import fetchAuth from '../../fetchAuth.js';
import { Helmet } from "react-helmet-async";
import './Favorites.css';

class Favorites extends Component {
  constructor () {
    super()
    this.state = {
			pageLoaded: false,
      presentationsLoaded: false,
      favoritePresentations: [],
      recentPresentations: []
    };
  }

	componentDidMount() {
    this.getData();
	}

	getData() {
    fetchAuth(`/api/favorites`)
    .then(response => response.json())
    .then(json => {
      if (json) {
        this.setState(
          {
						pageLoaded: true,
            presentationsLoaded: true,
            favoritePresentations: FormatPresentationJson(json, "favorite_presentations"),
            recentPresentations: FormatPresentationJson(json, "recent_presentations"),
          }
        )
      }
    })
	}
	  
  render() {
    const panes = [
      {
        menuItem: { key: 'favorites', icon: 'star', content: 'Favorites' },
        render: () =>
        <Tab.Pane>
          {(this.state.favoritePresentations.length > 0) ?
            ( 
              <CellGrid presentations={this.state.favoritePresentations} pageLoaded={this.state.pageLoaded} presentationsLoaded={this.state.presentationsLoaded} columns={4} />
            ) : (
              "When you favorite a presentation to watch later, it will appear on this list."
            )
          }
        </Tab.Pane>,
      },
      {
        menuItem: { key: 'recents', icon: 'eye', content: 'Recently Watched' },
        render: () =>
        <Tab.Pane>
          {(this.state.recentPresentations.length > 0) ?
            ( 
              <CellGrid presentations={this.state.recentPresentations} pageLoaded={this.state.pageLoaded} presentationsLoaded={this.state.presentationsLoaded} columns={4} />
            ) : (
              "This page will track the most recent presentations that you watch so you can come back to them later."
            )
          }
        </Tab.Pane>,
      }
    ]    
		const pageLoaded = this.state.pageLoaded
    if (pageLoaded === false) {
      return (
        <Loading />
      )
    }
    return (
      <Container fluid className="renewPageMainContentContainer profilePageMainContentContainer">
        <Helmet>
          <title>
            My Favorites 
          </title>
        </Helmet>
       <Grid stackable className="renewPageMainContentGrid">
          <Grid.Row reversed="tablet computer" className="renewPageMainContentRow">
            <Grid.Column mobile={16} tablet={16} computer={16}>
            <Tab panes={panes} />
            </Grid.Column>
          </Grid.Row>            
        </Grid>
      </Container>         
    )
  }
}

export default Favorites;