import React, { Component } from 'react';
import { Pagination } from 'semantic-ui-react'
import './PaginationBar.css';

class PaginationBar extends Component {

  render() {

    return (    
      <Pagination
        className={this.props.position}
        color="blue"
        activePage={this.props.page} 
        totalPages={this.props.totalpages} 
        siblingRange={2}
        firstItem={null}
        lastItem={null}
        onPageChange={this.props.onPageChange} />
    );
  }
}

export default PaginationBar;
