import React, { Component } from 'react';
import { Button, Container, Grid, Header, Icon, Image } from 'semantic-ui-react'
import CellGrid from '../CellGrid/CellGrid.js';
import FormatPresentationJson from '../FormatPresentationJson/FormatPresentationJson.js';
import Loading from '../Loading/Loading.js';
import fetchAuth from '../../fetchAuth.js';
import history from "../../history";
import { Helmet } from "react-helmet-async";
import './SpeakerPage.css';

class SpeakerPage extends Component {
  constructor() {
    super()
    this.state = {
      pageLoaded: false,
      presentationsLoaded: false,
      speaker: {},
      presentations: [],
      hasTwitter: false,
      hasLinkedin: false
};
    this.getData = this.getData.bind(this)
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    fetchAuth(`/api/speakers/${this.props.match.params.id}`)
      .then(response => {
        if (response.status === 404) {
          history.push({
            pathname: "/speakers",
            state: {
              visible: true,
              success: false,
              message: "We could not find that speaker."
            }
          })
          }
        return response.json();
      })
      .then(json => {
        if (json) {
          this.setState(
            {
              speaker: json.speaker,
              presentations: FormatPresentationJson(json, "presentations"),
              pageLoaded: true,
              presentationsLoaded: true,
              hasTwitter: (typeof json.speaker.data.attributes.twitter === "string" && json.speaker.data.attributes.twitter.length > 0),
              hasLinkedin: (typeof json.speaker.data.attributes.linkedin === "string" && json.speaker.data.attributes.linkedin.length > 0)
            }
          )
        }
      })
  }

  render() {
    const pageLoaded = this.state.pageLoaded
    if (pageLoaded === false) {
      return (
        <Loading />
      )
    }
    return (
      <Container fluid className="mainContentContainer speakerrMainContentContainer">
        <Helmet>
          <title>
            {this.state.speaker.data.attributes.full_name} 
          </title>
          <meta name="description" content={`Videos on recruiting, sourcing, & talent acquisition featuring ${this.state.speaker.data.attributes.full_name}.`} />
          <meta property="og:title" content={this.state.speaker.data.attributes.full_name} />
          <meta property="og:description" content={`Videos on recruiting, sourcing, & talent acquisition featuring ${this.state.speaker.data.attributes.full_name}.`} />
          <meta property="og:image" content={this.state.speaker.data.attributes.image} />
        </Helmet>
        <Grid container>
          <Container className="speakerHeader">
            <Grid stackable>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={4} computer={3} widescreen={3} className="speakerHeaderImageColumn">
                  <Image size="small" circular src={this.state.speaker.data.attributes.image} className="speakerHeaderImage"  verticalAlign="middle" /> 
                </Grid.Column>
                <Grid.Column mobile={16} tablet={11} computer={12} widescreen={10} verticalAlign="middle">
                <Grid.Row className="speakerNameRow">
                    <Header as='h1' className="speakerName">
                      {this.state.speaker.data.attributes.full_name} 
                    </Header>                  
                  </Grid.Row>
                  <Grid.Row className="bioRow">
                    <div as="h3" className="bio">{
                      this.state.speaker.data.attributes.bio}
                    </div>
                  </Grid.Row>
                  { (this.state.hasTwitter || this.state.hasLinkedin) ? (
                    <Grid.Row className="speakerButtonRow">
                      { this.state.hasTwitter ? (
                        <Button
                          compact
                          icon
                          target="_blank"
                          rel="noopener noreferrer"
                          className="twitterButton"
                          as="a"
                          href={this.state.speaker.data.attributes.twitter}
                        >
                          <Icon name="twitter square" size="big" />
                        </Button>
                      ) : (
                        null
                      )}
                      { this.state.hasLinkedin ? (
                        <Button
                          compact
                          icon
                          target="_blank"
                          rel="noopener noreferrer"
                          className="linkedinButton"
                          as="a"
                          href={this.state.speaker.data.attributes.linkedin}
                        >
                          <Icon name="linkedin" size="big" />
                        </Button>
                    ) : (
                        null
                      )}
                    </Grid.Row>
                  ) : (
                    null
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Container className="presentationsPageContainer">
            <Grid>
              <Grid.Row className="presentationsHeaderRow">
                <Grid.Column>
                  <div className="presentationsHeader">{this.state.speaker.data.attributes.first_name}'s presentations</div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <CellGrid presentations={this.state.presentations} pageLoaded={this.state.pageLoaded} presentationsLoaded={this.state.presentationsLoaded} columns={4} />
          </Container>
        </Grid>
      </Container>
    );
  }
}

export default SpeakerPage;
