import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react'
import ProfileForm from './ProfileForm/ProfileForm.js'
import PasswordForm from './PasswordForm/PasswordForm.js'
import Loading from '../Loading/Loading.js';
import fetchAuth from '../../fetchAuth.js';
import ProfileSegment from '../Profile/ProfileSegment/ProfileSegment.js';
import { Helmet } from "react-helmet-async";
import './Profile.css';

class Profile extends Component {
  constructor () {
    super()
    this.state = {
			pageLoaded: false,
			error: false,
			errorMessage: "",
			currentUser: {},
      showEditForm: false,
      showPasswordForm: false
    };
    this.handleError = this.handleError.bind(this);
    this.handleChangePasswordClick = this.handleChangePasswordClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
  }

	componentDidMount() {
    this.getUser();
	}

	getUser() {
    fetchAuth(`/api/profile`)
    .then(response => response.json())
    .then(json => {
      if (json) {
        this.setState(
          {
						pageLoaded: true,
            currentUser: json.current_user
          }
        )
      }
    })
	}
	
  handleError(error) {
      this.setState({error: true});
      this.setState({errorMessage: error});
      setTimeout(
        function() {
          this.setState({ error: false});
          this.setState({ errorMessage: "" });
        }
        .bind(this),
        4000
      );
  }

  handleChangePasswordClick() {
    this.setState(
      {
        showEditForm: false,
        showPasswordForm: true
      }
    )
}

  handleEditClick() {
    this.setState(
      {
        showEditForm: true,
        showPasswordForm: false
      }
    )
}
  
  render() {
		const pageLoaded = this.state.pageLoaded
    if (pageLoaded === false) {
      return (
        <Loading />
      )
    }
    return (
      <Container fluid className="renewPageMainContentContainer profilePageMainContentContainer">
        <Helmet>
          <title>
            My Profile 
          </title>
        </Helmet>
       <Grid stackable className="renewPageMainContentGrid">
          <Grid.Row reversed="tablet computer" className="renewPageMainContentRow">
            <Grid.Column mobile={16} tablet={8} computer={8} className="rightColumn">
                <ProfileForm showForm={this.state.showEditForm} onErrorMessage={this.handleError} user={this.state.currentUser} error={this.state.error} errorMessage={this.state.errorMessage} />
                <PasswordForm showForm={this.state.showPasswordForm} onErrorMessage={this.handleError} user={this.state.currentUser} error={this.state.error} errorMessage={this.state.errorMessage} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} className="leftColumn">
              <ProfileSegment profile={this.state.currentUser} handleEditClick={this.handleEditClick} handleChangePasswordClick={this.handleChangePasswordClick} error={this.state.error} errorMessage={this.state.errorMessage} />
            </Grid.Column>
          </Grid.Row>            
        </Grid>
      </Container>         
    )
  }
}

export default Profile;