import React from 'react';
import { Step } from 'semantic-ui-react';
import { withRouter, Link } from "react-router-dom";
import './SubscribeSteps.css';

class SubscribeSteps extends React.Component {

	getSteps(path) {
		let currentOne, currentTwo, currentThree, currentFour, activeOne, activeTwo, activeThree, activeFour;

		switch (path) {
			case "/register":
				currentOne = true;
				currentTwo = false;
				currentThree = false;
				currentFour = false;
				activeTwo = true;
				break;
			case "/subscribe":
				currentOne = true;
				currentTwo = true;
				currentThree = false;
				currentFour = false;
				activeThree = true;
				break;
			case "/renew":
				currentOne = true;
				currentTwo = true;
				currentThree = false;
				currentFour = false;
				activeThree = true;
				break;
			case "/confirm":
				currentOne = true;
				currentTwo = true;
				currentThree = true;
				currentFour = false;
				activeFour = true;
				break;
				default:
		}
		
		return [currentOne, currentTwo, currentThree, currentFour, activeOne, activeTwo, activeThree, activeFour]
	}

  render() {
		const path = this.props.location.pathname
		const [currentOne, currentTwo, currentThree, currentFour, activeOne, activeTwo, activeThree, activeFour] = this.getSteps(path)
    return (
			<Step.Group fluid ordered className="registerSteps">
				<Step
					as={Link}
					to={{
						pathname: "/join",
					}}

					completed={currentOne}
					active={activeOne}
				>
					<Step.Content>
						<Step.Title>Plan</Step.Title>
						<Step.Description>Choose a plan</Step.Description>
					</Step.Content>
				</Step>
		
				<Step completed={currentTwo} active={activeTwo}>
					<Step.Content>
						<Step.Title>Register</Step.Title>
						<Step.Description>Enter account information</Step.Description>
					</Step.Content>
				</Step>
		
				<Step completed={currentThree} active={activeThree}>
					<Step.Content>
						<Step.Title>Subscribe</Step.Title>
						<Step.Description>Enter billing information</Step.Description>
					</Step.Content>
				</Step>

				<Step completed={currentFour} active={activeFour}>
					<Step.Content>
						<Step.Title>Thank You!</Step.Title>
					</Step.Content>
				</Step>
			</Step.Group>
		);
  }
}
    
export default withRouter(SubscribeSteps);