import React, { Component } from 'react';
import history from '../../history';
import WithAuthPublic from '../Auth/WithAuthPublic/WithAuthPublic';


class Attendee extends Component {

  componentDidMount() {
    history.push({
      pathname: "/register",
      search: `?plan=attendee-access`,
    })
  }

  render() {
    return(
      <>
      </>
    )
  }

}

export default WithAuthPublic(Attendee);
