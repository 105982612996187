import React, { Component } from 'react';
import { Container, Grid, Segment, Sidebar } from 'semantic-ui-react'
import TopMenu from '../../TopMenu/TopMenu.js'; 
import SideMenu from '../../SideMenu/SideMenu.js'; 
import Flash from '../../Flash/Flash.js'; 
import RenewMessage from '../../RenewMessage/RenewMessage.js'; 
import WithAuthPaywall from '../../Auth/WithAuthPaywall/WithAuthPaywall';
import history from "../../../history";
import { Route } from 'react-router-dom';
import './LayoutPaywall.css';

class LayoutPaywall extends Component {
  constructor() {
    super()
    this.state = {
      showFlash: false,
      flashSuccess: false,
      flashMessage: "",
      showSideBar: false,
      icon: "bars",
      showRenewalMessage: false,
      renewalDate: "",
      daysLeft: ""
  };
    this.toggleSideBar = this.toggleSideBar.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    this.showFlash();
    this.showRenewalMessage();
  }

  showRenewalMessage() {
    const subscription = this.props.subscription
    if (subscription.auto_renew === false || typeof subscription.expires_at === "string") {
      let renewalDate = ""
      if (typeof subscription.expires_at === "string") {
        renewalDate = subscription.expires_at
      } else {
        renewalDate = subscription.current_period_ends_at
      }
      const today = new Date()
      const expirationDate = new Date(renewalDate)
      const daysLeft = Math.floor((expirationDate - today) / (3600*24*1000));
      let showRenewalMessage = true
      if (daysLeft > 30) {
        showRenewalMessage = false 
      } else if (localStorage.getItem("subscriptionReminder") && JSON.parse(localStorage.getItem("subscriptionReminder"))["showRenewalMessage"] === false) {
        showRenewalMessage = false
      }
      this.setState(
        {
          showRenewalMessage: showRenewalMessage,
          renewalDate: renewalDate,
          daysLeft: daysLeft,
          pageLoaded: true
        }
      )
    }
    if (localStorage.getItem("subscriptionReminder")) {
      const expirationTime = new Date(JSON.parse(localStorage.getItem("subscriptionReminder"))["expiration"])
      if (new Date() > expirationTime) {
        localStorage.removeItem("subscriptionReminder")
      }  
    }
  }

  showFlash() {
    if (history.location.state && history.location.state.message) {
      this.setState({
        showFlash: history.location.state.visible,
        flashSuccess: history.location.state.success,
        flashMessage: history.location.state.message
      })
      setTimeout(
        function() {
          this.setState({
            showFlash: false,
            flashSuccess: false,
            flashMessage: ""
          })
        }
        .bind(this),
        7500
      );
    }
  }

  toggleSideBar() {
    this.setState(prevState => ({
      showSideBar: !prevState.showSideBar,
      icon: prevState.icon === "bars" ? "close" : "bars"
    }));    
  }

  handleDismiss () {
    const myDate = new Date();
    myDate.setHours(myDate.getHours() + 24 );
    localStorage.setItem('subscriptionReminder', JSON.stringify({
      expiration: myDate,
      showRenewalMessage: false
    }));     
    this.setState({
      showRenewalMessage: false,
    })
  }

  render() {
		const activeItem = this.props.computedMatch.path
    return (
      <Container fluid className="pageContainer topContainer">
        <Sidebar.Pushable
          as={Segment}
          className="sideBarWrapper"
        >
          <Sidebar
            className="ereBlue"
            animation='push'
            visible={this.state.showSideBar}
          >
            <SideMenu activeItem={activeItem} currentUser={this.props.currentUser} hostedLoginToken= {this.props.hostedLoginToken} subscription={this.props.subscription} showSideBar={this.state.showSideBar} toggleSideBar={this.toggleSideBar} />
          </Sidebar>
          <Sidebar.Pusher dimmed={this.state.showSideBar} onClick={this.state.showSideBar ? this.toggleSideBar : null}>
            <Container fluid>
              <Grid>
                <Grid.Row>
                  <div className="menuColumn">
                    <SideMenu activeItem={activeItem} currentUser={this.props.currentUser} hostedLoginToken= {this.props.hostedLoginToken} subscription={this.props.subscription} showSideBar={this.state.showSideBar} toggleSideBar={this.toggleSideBar} />
                  </div>
                  <div className="notMenuColumn">
                    <TopMenu match={this.props.computedMatch} currentUser={this.props.currentUser} hostedLoginToken= {this.props.hostedLoginToken} subscription={this.props.subscription} showSideBar={this.state.showSideBar} toggleSideBar={this.toggleSideBar} icon={this.state.icon} />
                    {(this.state.showFlash || this.state.showRenewalMessage) ? (
                      <Grid container className="mainMessageContainer">
                        <RenewMessage visible={this.state.showRenewalMessage} daysLeft={this.state.daysLeft} handleDismiss={this.handleDismiss} />
                        <Flash visible={this.state.showFlash} success={this.state.flashSuccess} message={this.state.flashMessage} />
                      </Grid>
                    ) : (
                      null
                    )}
                    <Route {...this.props} />
                  </div>
                </Grid.Row>
              </Grid>
            </Container>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Container>
    );
  }
}

export default WithAuthPaywall(LayoutPaywall);