import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Header,
  Message,
  Sticky,
  Transition,
} from "semantic-ui-react";
import { RecurlyProvider, Elements } from "@recurly/react-recurly";
import { Link } from "react-router-dom";
import fetchAuth from "../../fetchAuth.js";
import history from "../../history";
import queryString from "query-string";
import SubscribeSteps from "../SubscribeSteps/SubscribeSteps.js";
import RegisterForm from "./RegisterForm/RegisterForm.js";
import PlanSegment from "./PlanSegment/PlanSegment.js";
import { Helmet } from "react-helmet-async";
import "./Register.css";

export default function Register(props) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  const [plan, setPlan] = useState({});

  useEffect(() => {
    const search = queryString.parse(props.location.search);
    if ("plan" in search) {
      getPlan(search.plan);
    } else {
      history.push({
        pathname: "/join",
        state: {
          visible: true,
          success: false,
          message: "Please choose a subscription plan.",
        },
      });
    }
  }, [props.location.search]);

  function getPlan(plan) {
    fetchAuth(`/api/get_plan?plan=${plan}`)
      .then((response) => response.json())
      .then((json) => {
        if (json) {
          setPlan(json.plan.attributes);
          setPageLoaded(true);
        }
      });
  }

  function handleError(error) {
    setError(true);
    setErrorMessage(error);
    setTimeout(function () {
      setError(false);
      setErrorMessage("");
    }, 4000);
  }

  if (pageLoaded === false) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>
      <Sticky className="subscribeStepsSticky">
        <SubscribeSteps />
      </Sticky>
      <Container fluid className="registerPageMainContentContainer">
        <RecurlyProvider
          publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}
          required={["cvv"]}
        >
          <Elements>
            <Grid stackable className="registerPageMainContentGrid">
              <Grid.Row
                reversed="tablet computer"
                className="registerPageMainContentRow"
              >
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  className="rightColumn"
                >
                  <Grid
                    verticalAlign="middle"
                    textAlign="center"
                    className="authGrid"
                  >
                    <Grid.Row className="registerHeaderRow">
                      <Grid.Column width="12" textAlign="left">
                        <div className="separator"></div>
                        <Header as="h2" className="registerHeader">
                          Join ERE Pro
                          <Header.Subheader className="registerSubHeader">
                            Talent Acquisition Knowledge
                          </Header.Subheader>
                        </Header>
                        <div className="registerText">{plan.description}</div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="registerErrorRow">
                      <Grid.Column width="12" textAlign="left">
                        <Transition
                          visible={error}
                          animation="scale"
                          duration={{ show: 500, hide: 0 }}
                        >
                          <Message error header={errorMessage} />
                        </Transition>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="registerFormRow">
                      <Grid.Column width="12" textAlign="left">
                        <RegisterForm
                          onErrorMessage={handleError}
                          plan={plan.code}
                        />
                        <div className="loginHeader">
                          Already registered?
                          <Link to="/login" className="loginLink">
                            Login
                          </Link>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  className="leftColumn"
                >
                  <PlanSegment plan={plan} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Elements>
        </RecurlyProvider>
      </Container>
    </>
  );
}
