import { withRouter } from 'react-router-dom';
import React from 'react';
import { Card, Image } from 'semantic-ui-react'
import { v4 as uuid } from 'uuid';
import './DisplayCard.css';

class DisplayCard extends React.Component {
  constructor(props) {
    super(props);
    this.getSpeakerNames = this.getSpeakerNames.bind(this);
  }

  addSpeakerLink(text, id) {
    return <a href={`/speakers/${id}`}>{text}</a>
  }

  getDate(dateString) {
    const date = new Date(dateString).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC' 
    });
    return date
  }

  getHighlightedText(text, q) {
    if (q) {
      const parts = text.split(new RegExp(`(${q})`, 'gi'));
      return parts.map(part => {
        const uniqueId = uuid();
        if (part.toLowerCase() === q.toLowerCase()) {
          return (
            <span className="highlight" key={uniqueId}>{part}</span>
          )
        } else {
          return (
          <span key={uniqueId}>{part}</span>
          )
        }
      })
    } else {
      return text;
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getSpeakerNames(speakers) {
    let nameBlock = null
    nameBlock = speakers.map(speaker => {
      const uniqueId = uuid();
      return (
        <div className="speakerMeta" key={`speakerName-${uniqueId}`}>
          {this.addSpeakerLink(this.getHighlightedText(speaker.name, this.props.q), speaker.slug)}
        </div>
      )
    })
    return nameBlock;
  }

  getTopics(topics) {
    let topicsBlock = null
    if (topics.length >= 1) {
      topicsBlock = topics.map(topic => {
        const uniqueId = uuid();
        return (
          <span key={`topic-${uniqueId}`}>
            <a href={`/topics/${topic}`}  className="topicName">
              {this.getHighlightedText(topic, this.props.q)}
            </a>
            {' '}
          </span>
        )
      })
    }
    return topicsBlock
  }

  getImage(speaker, presentation) {
    if (this.props.match.path === "/speakers/:id") {
      return (
        null
      )
    } else {
      return (
        <div className="speakerCardImage">
          <figure>
            <Image src={speaker.attributes.image} as="a" href={`/presentations/${presentation.attributes.slug}`} />
          </figure>
        </div>
      )
    }
  }

  render() {
    const presentation = this.props.presentation;
    let speakers = []
    presentation.presentation_speaker.forEach(ps => {
      const speaker = presentation.speakers.find(speaker => speaker.id === ps.relationships.speaker.data.id);
      speakers.push({name: speaker.attributes.full_name, id: speaker.id, slug: speaker.attributes.slug});
    })
    const speaker = presentation.speakers[Math.floor(Math.random() * presentation.speakers.length)];
    const presentationDate = this.getDate(presentation.attributes.date)
    return (
      <Card fluid className="speakerCard">
        {this.getImage(speaker, presentation)}
        <Card.Content>
          <div className="separator"></div>
          <Card.Header as="a" className="presentationTitle" href={`/presentations/${presentation.attributes.slug}`}>
            {this.getHighlightedText(presentation.attributes.title, this.props.q)}
          </Card.Header>
          <Card.Meta className="dateMeta">
            {presentationDate}
          </Card.Meta>
          {this.getSpeakerNames(speakers)}
          <Card.Description className="descriptionMeta">
            {this.getHighlightedText(presentation.attributes.short_description, this.props.q)}
          </Card.Description>
          <div className="topicMeta">
            {this.getTopics(presentation.attributes.tag_list)}
          </div>
        </Card.Content>
      </Card>
    );
  }
}
    
export default withRouter(DisplayCard);