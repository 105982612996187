import React, { Component } from 'react';
import { Container, Grid, Header, Image } from 'semantic-ui-react'
import Search from '../Search/Search.js';
import SearchResults from '../SearchResults/SearchResults.js';
import FormatPresentationJson from '../FormatPresentationJson/FormatPresentationJson.js';
import Loading from '../Loading/Loading.js';
import fetchAuth from '../../fetchAuth.js';
import history from "../../history";
import { Helmet } from "react-helmet-async";
import './EventPage.css';

class EventPage extends Component {
  constructor () {
    super()
    this.state = {
      pageLoaded: false,
      presentationsLoaded: false,
      currentPage: 1,
      perPage: 12,
      totalPages: null,
      event: {},
      presentations: [],
      q: "",
      typingTimeout: 0
    };
    this.getData = this.getData.bind(this)
    this.handleQ = this.handleQ.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  componentDidMount () {
    this.getData();
  }

  getData () {
    this.waitingFor = this.state.q
    fetchAuth(`/api/events/${this.props.match.params.id}?page=${this.state.currentPage}&perpage=${this.state.perPage}&q=${this.state.q}`)
    .then(response => {
      if (response.status === 404) {
        history.push({
          pathname: "/events",
          state: {
            visible: true,
            success: false,
            message: "We could not find that event."
          }
        })
        }
      return response.json();
    })
    .then(json => {
      if (json) {
        if (this.state.q === this.waitingFor) {
          this.setState(
            {
              brand: json.brand,
              event: json.event,
              presentations: FormatPresentationJson(json, "presentations"),
              totalPages: json.pagination.total_pages,
              pageLoaded: true,
              presentationsLoaded: true
            }
          )
        }
      }
    })
  }

  handleQ(event) {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      q: event.target.value,
      typing: false,
      presentationsLoaded: false,
      currentPage: 1,
      typingTimeout: setTimeout(function () {
        self.getData()
      }, 300)
    });
  }

  handlePaginationChange(e, data) {
    this.setState(
      {
        presentationsLoaded: false,
        currentPage: data.activePage
      }, () => this.getData()
    )
  }

  render() {
    const pageLoaded = this.state.pageLoaded
    if (pageLoaded === false) {
      return (
        <Loading />
      )
    }
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long'
    }).format(Date.parse(this.state.event.data.attributes.start_date));
    return (
      <Container fluid className="mainContentContainer">
        <Helmet>
          <title>
            {this.state.event.data.attributes.name} 
          </title>
          <meta name="description" content={`Videos and presentations about recruiting, sourcing, and talent acquisition from ${this.state.event.data.attributes.name}.`} />
          <meta property="og:title" content={this.state.event.data.attributes.name} />
          <meta property="og:description" content={`Videos and presentations about recruiting, sourcing, and talent acquisition from ${this.state.event.data.attributes.name}.`} />
          <meta property="og:image" content={this.state.brand.data.attributes.logo} />
        </Helmet>
        <Grid container>
          <Container className="eventHeader">
            <Grid stackable centered>
              <Grid.Row className="eventHeaderRow"> 
                <Grid.Column mobile={16} tablet={4} computer={3} className="eventHeaderImageColumn">
                  <Image size="small" src={this.state.brand.data.attributes.logo} className="brandLogo" />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={12} computer={13} verticalAlign="middle">
                  <Header as="h1" className="eventName">
                    {this.state.event.data.attributes.name}
                  </Header>
                  <div className="eventDate">
                    {formattedDate}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Search onChange={this.handleQ} loading={!this.state.presentationsLoaded} pageName="presentations" />
            <SearchResults presentations={this.state.presentations} q={this.state.q} presentationsLoaded={this.state.presentationsLoaded} onPageChange={this.handlePaginationChange} currentPage={this.state.currentPage} totalPages={this.state.totalPages} />
          </Container>
        </Grid>
      </Container>
    );
  }
}

export default EventPage;
