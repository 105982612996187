import React, { Component } from "react";
import { Container, Grid, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import CardSlider from "../CardSlider/CardSlider.js";
import FormatPresentationJson from "../FormatPresentationJson/FormatPresentationJson.js";
import Loading from "../Loading/Loading.js";
import fetchAuth from "../../fetchAuth.js";
import { Helmet } from "react-helmet-async";
import { isSignedIn } from "devise-token-client";
import "./Home.css";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      pageLoaded: false,
      featuredEvent: {},
      featuredTopic: {},
      featuredEventPresentations: [],
      featuredTopicPresentations: [],
      newestPresentations: [],
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    fetchAuth(`/api/home`)
      .then((response) => response.json())
      .then((json) => {
        if (json) {
          this.setState({
            featuredEvent: json.featured_event.data.attributes,
            featuredTopic: json.featured_tag.data.attributes,
            featuredEventPresentations: FormatPresentationJson(
              json,
              "featured_event_presentations"
            ),
            featuredTopicPresentations: FormatPresentationJson(
              json,
              "featured_tag_presentations"
            ),
            newestPresentations: FormatPresentationJson(
              json,
              "newest_presentations"
            ),
            pageLoaded: true,
          });
        }
      });
  }

  render() {
    const isLoggedIn = isSignedIn();
    const pageLoaded = this.state.pageLoaded;
    if (pageLoaded === false) {
      return <Loading />;
    }
    return (
      <Container fluid className="mainContentContainer">
        <Helmet>
          <title>Home</title>
        </Helmet>
        <Grid container>
          <Container className="mainPageContainer">
            <Segment raised className="featuredContainer introContainer">
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    Welcome to ERE Pro, the world’s largest library of talent
                    acquisition media. ERE Pro features deep dives into the
                    recruiting challenges that real talent acquisition
                    professionals face daily, from practitioners that have
                    already tackled them.
                  </Grid.Column>
                </Grid.Row>
                {isLoggedIn ? null : (
                  <Grid.Row>
                    <Grid.Column>
                      <HashLink to="/join#subscribe">Subscribe today</HashLink>
                      {
                        " for unlimited access to exclusive lectures, real case studies and proven strategies from talent acquisition leaders."
                      }
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </Segment>
            <Segment basic className="featuredHeaderSegment">
              <div className="featuredHeader featuredEventHeader">
                Featured Event:{" "}
                <Link to={`/events/${this.state.featuredEvent.slug}`}>
                  {this.state.featuredEvent.name}
                </Link>
              </div>
            </Segment>
            <Segment
              raised
              className="featuredContainer featuredEventContainer"
            >
              <Grid className="sliderGrid">
                <CardSlider
                  presentations={this.state.featuredEventPresentations}
                />
              </Grid>
            </Segment>
            <Segment basic className="featuredHeaderSegment">
              <div className="featuredHeader featuredTopicHeader">
                Featured Topic:{" "}
                <Link to={`/events/${this.state.featuredTopic.name}`}>
                  {this.state.featuredTopic.display_name}
                </Link>
              </div>
            </Segment>
            <Segment
              raised
              className="featuredContainer featuredTopicContainer"
            >
              <Grid className="sliderGrid">
                <CardSlider
                  presentations={this.state.featuredTopicPresentations}
                />
              </Grid>
            </Segment>
            <Segment basic className="featuredHeaderSegment">
              <div className="featuredHeader featuredNewestHeader">
                Recently Added
              </div>
            </Segment>
            <Segment
              raised
              className="featuredContainer featuredNewestContainer"
            >
              <Grid className="sliderGrid">
                <CardSlider presentations={this.state.newestPresentations} />
              </Grid>
            </Segment>
          </Container>
        </Grid>
      </Container>
    );
  }
}

export default Home;
