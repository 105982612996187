import React, { Component } from 'react';
import { Grid, Input } from 'semantic-ui-react';
import './Search.css';

class Search extends Component {

  render() {
    return (
      <Grid centered>
        <Grid.Row className="searchRow presentationsSearchRow">
          <Grid.Column mobile={16} tablet={12} computer={12} textAlign="center">
            <Input
              fluid
              size='large'
              icon='search'
              iconPosition='left'
              loading={this.props.loading}
              placeholder={`Search${this.props.pageName ? (" " + this.props.pageName) : ("")}...`}
              onChange={this.props.onChange} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Search;
