import React, { Component } from 'react'
import { Button, Grid, Icon, Label, List, Placeholder, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import PaginationBar from '../PaginationBar/PaginationBar.js'; 
import './TopicItems.css';

class TopicItems extends Component {

  sortTopics(topics, columns) {
    let sortedTopics = [], counter, numberOfTopics
    for (counter=0,numberOfTopics=topics.length; counter<numberOfTopics; counter+=columns) {
        sortedTopics.push(topics.slice(counter,counter+columns));
    }
    return sortedTopics
  }

  getHighlightedText(text, q) {
    if (q) {
      const parts = text.split(new RegExp(`(${q})`, 'gi'));
      return parts.map(part => {
        const uniqueId = uuid();
        if (part.toLowerCase() === q.toLowerCase()) {
          return (
            <span className="highlight" key={uniqueId}>{part}</span>
          )
        } else {
          return (
          <span key={uniqueId}>{part}</span>
          )
        }
      })
    } else {
      return text;
    }
  }

  hasTopics() {
    const numberOfColumns = 4
    if (this.props.topics.length > 0) {
      const sortedTopics = this.sortTopics(this.props.topics, numberOfColumns)
      return (
        <Grid stackable doubling columns={numberOfColumns}>
          <Grid.Row centered>
            <PaginationBar 
              position="topPagination"
              page={this.props.currentPage} 
              totalpages={this.props.totalPages} 
              onPageChange={this.props.onPageChange}
            />
          </Grid.Row>
          {sortedTopics.map(row => {
            const uniqueIdRow = uuid();
            return (
              <Grid.Row key={uniqueIdRow} className="topicsItemsRow">
                {row.map(topic => {
                  const uniqueId = uuid();
                  return (
                    <Grid.Column key={uniqueId} className="topicsItemsColumn">
                      {this.props.topicsLoaded ?  (
                        <Button as={Link} basic to={`/topics/${topic.attributes.name}`} labelPosition="right" className="topicLink">
                          <Button icon to={`/topics/${topic.attributes.name}`}>
                            {this.getHighlightedText(topic.attributes.display_name, this.props.q)}
                          </Button>
                          <Label basic>
                            <Icon name='tags' />
                            {topic.attributes.taggings_count}
                          </Label>
                        </Button>
                      ) : (
                        <Placeholder>
                        <Placeholder.Header image rectangle>
                          <Placeholder.Line />
                        </Placeholder.Header>
                      </Placeholder>
                    )}
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            )
          })}
          <Grid.Row centered>
            <PaginationBar 
              position="bottomPagination"
              page={this.props.currentPage} 
              totalpages={this.props.totalPages} 
              onPageChange={this.props.onPageChange}
            />
          </Grid.Row>
        </Grid>
      )
    } else {
      return (
        <Grid stackable doubling columns={numberOfColumns}>
          <Grid.Row cewntered>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width="16">
              <Segment secondary size="large">
                <p>
                  Your search - 
                  {' '}
                  <strong>{
                    this.props.q}
                  </strong>
                  {' '}
                  - did not match any topics.
                </p>
                <p>
                  Suggestions:
                </p>
                <List>
                  <List.Item>
                    <List.Icon name='help' />
                    <List.Content>
                      Make sure all words are spelled correctly.
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='search' />
                    <List.Content>
                      Try different keywords.
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='users' />
                    <List.Content>
                      Try browsing topics by
                      {' '}
                      <Link to="/speakers"> 
                        speaker
                      </Link>
                      .
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='microphone' />
                    <List.Content>
                      Try browsing presentations by
                      {' '}
                      <Link to="/events"> 
                        event
                      </Link>
                      .
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='tags' />
                    <List.Content>
                      Try browsing presentations by
                      {' '}
                      <Link to="/topics"> 
                        topic
                      </Link>
                      .
                    </List.Content>
                  </List.Item>
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          </Grid.Row>
        </Grid>
      )
    }
  }

  render() {
    const results = this.hasTopics()
    return (
      <>
        {results} 
      </>
    );
  }
}
    
export default TopicItems;
