import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react'
import Search from '../Search/Search.js';
import TopicItems from '../TopicItems/TopicItems.js';
import Loading from '../Loading/Loading.js';
import fetchAuth from '../../fetchAuth.js';
import { Helmet } from "react-helmet-async";

class Topics extends Component {
  constructor () {
    super()
    this.state = {
      pageLoaded: false,
      topicsLoaded: false,
      currentPage: 1,
      perPage: 48,
      totalPages: null,
      topics: [],
      q: "",
      typingTimeout: 0
    };
    this.getData = this.getData.bind(this)
    this.handleQ = this.handleQ.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }

  componentDidMount () {
    this.getData();
  }

  getData() {
    this.waitingFor = this.state.q
    fetchAuth(`/api/tags?page=${this.state.currentPage}&perpage=${this.state.perPage}&q=${this.state.q}`)
    .then(response => response.json())
    .then(json => {
      if (json) {
        if (this.state.q === this.waitingFor) {
          this.setState(
            {
              topics: json.topics.data,
              totalPages: json.pagination.total_pages,
              pageLoaded: true,
              topicsLoaded: true
            }
          )
        }
      }
    })
  }

  handleQ(event) {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      q: event.target.value,
      typing: false,
      topicsLoaded: false,
      currentPage: 1,
      typingTimeout: setTimeout(function () {
        self.getData()
      }, 300)
    });
  }

  handlePaginationChange(e, data) {
    this.setState(
      {
        topicsLoaded: false,
        currentPage: data.activePage
      }, () => this.getData()
    )
  }

  render() {
    const pageLoaded = this.state.pageLoaded
    if (pageLoaded === false) {
      return (
        <Loading />
      )
    }
    return (
      <Container fluid className="mainContentContainer">
        <Helmet>
          <title>
            Topics 
          </title>
        </Helmet>
        <Grid container>
          <Container>
            <Search onChange={this.handleQ} loading={!this.state.topicsLoaded} pageName="topics" />
            <TopicItems topics={this.state.topics} q={this.state.q} topicsLoaded={this.state.topicsLoaded} onPageChange={this.handlePaginationChange} currentPage={this.state.currentPage} totalPages={this.state.totalPages} pageLoaded={this.state.pageLoaded} />
          </Container>
        </Grid>
      </Container>
    );
  }
}

export default Topics;