import React from 'react';
import { Card , Image} from 'semantic-ui-react'
import './CompactCard.css';

class CompactCard extends React.Component {

  getImage(speaker, presentation) {
    return (
      <div className="speakerCardImage">
        <figure>
          <Image src={speaker.attributes.image} as="a" href={`/presentations/${presentation.attributes.slug}`} />
        </figure>
      </div>
    )
  }

  render() {
    const presentation = this.props.presentation;
    let speakers = []
    presentation.presentation_speaker.forEach(ps => {
      const speaker = presentation.speakers.find(speaker => speaker.id === ps.relationships.speaker.data.id);
      speakers.push({name: speaker.attributes.full_name, id: speaker.id, slug: speaker.attributes.slug});
    })
    const speaker = presentation.speakers[Math.floor(Math.random() * presentation.speakers.length)];
    return (
      <Card className="speakerCard compactCard">
        {this.getImage(speaker, presentation)}
        <Card.Content>
          <div className="separator"></div>
          <Card.Header as="a" className="presentationTitle" href={`/presentations/${presentation.attributes.slug}`}>
            {presentation.attributes.title}
          </Card.Header>
        </Card.Content>
      </Card>
    );
  }
}
    
export default CompactCard;