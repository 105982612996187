import React, { Component } from 'react'
import { Header, Segment, Sticky } from 'semantic-ui-react'
import PricingPreview from '../Subscribe/SubscribeForm/PricingPreview/PricingPreview.js';
import './PlanSegment.css';

class PlanSegment extends Component {
  constructor () {
    super()
    this.makeSegment = this.makeSegment.bind(this);
  }


  makeSegment(price, autorenew, subheaderText, interval, trial, trialLength) {
    return (
      <Segment>
        <div className="separator">
        </div>
        <Header as="h2" className="planHeader">
            ERE Pro Membership
          <Header.Subheader className="planSubHeader">
          { subheaderText }
          </Header.Subheader>
        </Header>
        <div className="totalPrice">
          { this.props.promotionValid ?
            (
              <PricingPreview plan={this.props.plan} promotion={this.props.promotion} promotionValid={this.props.promotionValid} />
            ) : (
              <span>
                {"$" + price + " "}
              </span>
            )

          }
          <span className="period">
          {`/ ${interval}`}
          </span>
        </div>
        {(trialLength >= 1) ? (
          <div className="freeTrial">
            { `You won't be charged until after your ${trial} trial.` }
          </div>
        ) : (
          <>
          </>
        )}
      </Segment>
    )
  }

  convertPeriod(length, unit) {
    let period = `${length} ${unit}`.slice(0, -1);
    if (length === 12 && unit === "months") {
      period = "year"
    } else if (length === 3 && unit === "months") {
      period = "3 months"
    }
    return period
  }

  render() {
    const price = this.props.plan.currencies[0].attributes.unit_amount
    const autorenew = this.props.plan.auto_renew
    const subheaderText = this.props.plan.name
    const intervalLength = this.props.plan.interval_length
    const intervalUnit = this.props.plan.interval_unit
    const interval = this.convertPeriod(intervalLength, intervalUnit)
    const trialLength = this.props.plan.trial_length
    const trialUnit = this.props.plan.trial_unit
    const trial = this.convertPeriod(trialLength, trialUnit)
    return (
      <Sticky>
        { this.makeSegment(price, autorenew, subheaderText, interval, trial, trialLength)}
      </Sticky>
    )
  }
}

export default PlanSegment;