import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { isSignedIn, persistToken } from "devise-token-client";
import history from "../../../history";
import "./RegisterForm.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function RegisterForm(props) {
  const [waiting, setWaiting] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  function fetchData(endpoint, fields) {
    const reqObj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: fields,
    };
    fetch(endpoint, reqObj)
      .then((response) => {
        persistToken(response.headers);
        if (isSignedIn()) {
          history.push({
            pathname: "/subscribe",
            search: `?plan=${props.plan}`,
          });
        }
        return response.json();
      })
      .then((jsonResponse) => {
        if (jsonResponse.status === "error") {
          setWaiting(false);
          throw new Error(jsonResponse.errors.full_messages[0]);
        }
      })
      .catch((error) => {
        setWaiting(false);
        props.onErrorMessage(error.message);
      });
  }

  function handleSubmit(inquiry) {
    setWaiting(true);
    if (!executeRecaptcha) {
      props.onErrorMessage("Execute recaptcha not yet available");
      console.log("recaptcha is not ready");
      setWaiting(false);
    }

    executeRecaptcha("registerFormSubmit").then((gReCaptchaToken) => {
      checkRecaptcha(gReCaptchaToken, inquiry, setWaiting);
    });
  }

  const checkRecaptcha = (gReCaptchaToken, inquiry, setWaiting) => {
    fetch("/api/check_recaptcha", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        gRecaptchaToken: gReCaptchaToken,
        inquiry: inquiry,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === true) {
          const data = JSON.stringify(inquiry);
          fetchData("/api/auth", data);
        } else {
          setWaiting(false);
          props.onErrorMessage("Something went wrong.");
        }
      });
  };

  function getField(name, placeholder, type, errormessage, touched) {
    return (
      <>
        <div className={"field" + (errormessage && touched ? " error" : "")}>
          <Field name={name} placeholder={placeholder} type={type} />
        </div>
        <div className="errorDiv mobileErrorDiv">
          {touched && errormessage && <span>{errormessage}</span>}
        </div>
      </>
    );
  }

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Email is invalid.")
          .required("Email is required."),
        first_name: Yup.string().required("First name is required."),
        last_name: Yup.string().required("Last name is required."),
        password: Yup.string()
          .min(6, "Password must be at least 6 characters.")
          .required("Password is required."),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match.")
          .required("Password confirmation is required."),
      })}
      onSubmit={(fields) => {
        handleSubmit(fields);
      }}
    >
      {({ errors, status, touched }) => (
        <Form key="registerForm" noValidate className="ui form">
          <div className="two fields">
            {getField(
              "first_name",
              "First Name",
              "text",
              errors.first_name,
              touched.first_name
            )}
            {getField(
              "last_name",
              "Last Name",
              "text",
              errors.last_name,
              touched.last_name
            )}
          </div>
          <div className="errorDivRow">
            <div className="errorDiv errorDivColumn">
              {touched.first_name && errors.first_name && (
                <span>{errors.first_name}</span>
              )}
            </div>
            <div className="errorDiv errorDivColumn">
              {touched.last_name && errors.last_name && (
                <span>{errors.last_name}</span>
              )}
            </div>
          </div>
          {getField("email", "Email", "email", errors.email, touched.email)}
          {getField(
            "password",
            "Password",
            "password",
            errors.password,
            touched.password
          )}
          {getField(
            "password_confirmation",
            "Password confirmation",
            "password",
            errors.password_confirmation,
            touched.password_confirmation
          )}
          <div>
            <Button primary type="submit" className={waiting ? "disabled" : ""}>
              Join
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
