import React, { useState } from 'react';
import { CardElement, useRecurly } from '@recurly/react-recurly';
import { Icon, Segment } from 'semantic-ui-react';
import postAuth from '../../../postAuth.js';
import history from "../../../history";
import './RenewForm.css';

export function RenewForm (props) {
  const [input, setInput] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const formRef = React.useRef();
  const userecurly = useRecurly();

  function handleClick() {
    setInput('')
    props.handleClick(input)
  }

  function handlePromotion(event) {
    event.preventDefault();
    props.handlePromotion(input)
  }

  function handleSubmit (event) {
    event.preventDefault();
    setSubmitted(true);
    submitWithToken()     
  }
 
  function submitWithToken() {
    userecurly.token(formRef.current, (err, token) => {
      if (err) {
        setSubmitted(false)
        props.onErrorMessage(err.message)
      } else {
        let obj = {
          recurly_id: token.id,
          plan: props.plan         
        };
        if (Object.keys(props.promotion).length >= 1) {
          obj["coupon"] = props.promotion.code
        }
        const tokenId = JSON.stringify(obj)
        postAuth('/api/change_subscription', tokenId)
        .then(response => response.json())
        .then(jsonResponse => {
          if (jsonResponse.success === true) {
            history.push({
              pathname: "/confirm",
            })        
          } else if (jsonResponse.success === false) {
            setSubmitted(false)
            props.onErrorMessage(jsonResponse.errors)
          }
        })
      }
    });
  }

  function getField(name, placeholder, type, value) {
    return (
      <>
      <div className='field'>
      <input
          disabled
          className="ui input"
          name={name}
          data-recurly={name}
          placeholder={placeholder}
          type={type}
          defaultValue={value}
        />
      </div>
      <div className="errorDiv mobileErrorDiv">
      </div>
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit} ref={formRef} className="ui form">
      <div className="two fields">
        {getField("first_name", "First Name", "text", props.subscriber.first_name)}
        {getField("last_name", "Last Name", "text", props.subscriber.last_name)}
      </div>
      <div className="errorDivRow">
        <div className="errorDiv errorDivColumn">
        </div>
        <div className="errorDiv errorDivColumn">
        </div>
      </div>
      {getField("email", "Email", "email",props.subscriber.email)}
      <CardElement 
        className="cardElementWrapper"
        displayIcon={false}
        style={{
          fontFamily: "Lato", 
          fontSize:"14px",
        }}
      />

      {props.promotionValid && (
        <Segment color="blue" textAlign="left" className="promotionSegment">
          <div className="promotionHeader">
            Promotion Code
            <Icon link name='close' onClick={handleClick} />
          </div>
          <div className="promotionName">
            {props.promotion.hosted_page_description}
          </div>
        </Segment>
      )}
      {!props.promotionValid && props.showPromotionInput && (
        <div className="ui fluid action input promotionCodeInput">
          <input value={input} placeholder='Promotion Code' type="text" onChange={e => setInput(e.target.value)} />
          <button className="ui lightgrey button" onClick={handlePromotion}>
            Apply
          </button>
          <Icon link name="close"  size="large" className="closeApply" onClick={props.handleClosePromotionField} />
        </div>   
      )}
      <input 
        type="submit" 
        value="Renew"
        className="ui primary button"
        disabled={(submitted || props.showPromotionInput)}
      />
    </form>
  );
}
