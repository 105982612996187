import history from "./history";
import * as Auth from 'devise-token-client';

export default function fetchAuth(url, options = {}) {
  options.headers = Auth.requestHeaders()
  return window
    .fetch(url, options)
    .catch(error => console.log(error))
    .then(response => {
      if (response.status === 401) {
        Auth.removeToken()
        history.push({
          pathname: "/",
          state: {
            visible: true,
            success: false,
            message: "Please login again to access that page."
          }
        })
      }        
      if (response.headers.get('access-token')) {
        Auth.persistToken(response.headers)
      }
      return response
    })
}
