import React, { useState } from 'react';
import { Button, Grid, Loader } from 'semantic-ui-react'
import { Document, Page, pdfjs } from 'react-pdf';
import './PdfViewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewer(pdf) {
  const pdfUrl = pdf.pdfUrl
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function renderLoader() {
    return (
      <Loader active inverted size="massive" className="pdfloader" inline='centered' />
    );
  }

  return (
    <Grid>
      <Grid.Row className="pdfHeaderRow">
        <Grid.Column textAlign="center">
          <div className="pdfHeader">
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}          
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Document
            file={`${pdfUrl}`}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={renderLoader}
          >
            <Page
              pageNumber={pageNumber}
              renderTextLayer={false} />
          </Document>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Button.Group className="pdfControls">
            <Button
              basic
              color="primary"
              disabled={pageNumber <= 1}
              content="Prev"
              labelPosition='left'
              onClick={previousPage}
              icon="left arrow" 
              floated="left" />          
            <Button
              basic
              color="primary"
              disabled={pageNumber >= numPages}
              content="Next"
              labelPosition='right'
              onClick={nextPage}
              icon="right arrow"
              floated="right" />
          </Button.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}