import React, { Component } from 'react';
import { Button, Grid, Header, Message, Transition } from 'semantic-ui-react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import history from '../../../history';
import putAuth from '../../../putAuth.js';
import './ProfileForm.css';

class ProfileForm extends Component {
  constructor () {
    super()
    this.state = {
      submitted: false
    };
  }

  handleSubmit(fields) {
    this.setState({
      submitted: true,
    })
    const data = JSON.stringify(fields)
		putAuth('/api/auth', data)
    .then(response => {
			if(response.status === 200) {
				history.push({
					pathname: "/",
					state: {
						visible: true,
						success: true,
						message: "Profile updated!"
					}
				})
			}
      return response.json();
    })
    .then(jsonResponse => {
      if (jsonResponse.success === false) {
        this.setState({
          submitted: false,
        })    
        throw new Error(jsonResponse.errors.full_messages[0])
      }
    })
    .catch(error => {
      this.setState({
        submitted: false,
      })    
      this.props.onErrorMessage(error.message) 
    })
  }

  getField(name, placeholder, type, errormessage, touched) {
    return (
      <>
      <div className={'field' + (errormessage && touched ? ' error' : '')}>
      <Field
          name={name}
          placeholder={placeholder}
          type={type}
        />
      </div>
      <div className="errorDiv mobileErrorDiv">
        {touched && errormessage && <span>{errormessage}</span>}
      </div>
      </>
    )
  }

  render() {
    const error = this.props.error
    const errorMessage = this.props.errorMessage
    return (
    <Grid verticalAlign='middle' textAlign='center' className={this.props.showForm ? ("profileGrid authGrid") : ("noDisplay profileGrid authGrid")}>
      <Grid.Row className="renewHeaderRow">
        <Grid.Column width="12" textAlign="left">
          <div className="separator">
          </div>
          <Header as="h2" className="renewHeader">
            Edit Profile
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="renewErrorRow">     
        <Grid.Column width="12" textAlign="left">
          <Transition visible={error} animation='scale' duration={{show: 500, hide: 0}}>
            <Message error header={errorMessage} />
          </Transition>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="renewFormRow">
        <Grid.Column width="12" textAlign="left">
          <Formik
            initialValues={{
              first_name: this.props.user.first_name,
              last_name: this.props.user.last_name,
              email: this.props.user.email,
              id: this.props.user.id
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Email is invalid.')
                .required('Email is required.'),
              first_name: Yup.string()
                .required('First name is required.'),
              last_name: Yup.string()
                .required('Last name is required.'),
            })}
            onSubmit={fields => {
              this.handleSubmit(fields)
            }}
          >
            {({ errors, status, touched }) => (
              <Form key="profileForm" noValidate className="ui form">
                <div className="two fields">
                  {this.getField("first_name", "First Name", "text", errors.first_name, touched.first_name)}
                  {this.getField("last_name", "Last Name", "text", errors.last_name, touched.last_name)}
                </div>
                <div className="errorDivRow">
                  <div className="errorDiv errorDivColumn">
                    {touched.first_name && errors.first_name && <span>{errors.first_name}</span>}
                  </div>
                  <div className="errorDiv errorDivColumn">
                    {touched.last_name && errors.last_name && <span>{errors.last_name}</span>}
                  </div>
                </div>
                {this.getField("email", "Email", "email", errors.email, touched.email)}
                <div>
                  <Button primary type="submit" className={this.state.submitted ? ("disabled") : ("")}>
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    )
  }
}

export default ProfileForm;
