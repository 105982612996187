import React, { Component } from "react";
import { Container, Image } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";
import "./NotFound.css";

class NotFound extends Component {
  render() {
    return (
      <Container text textAlign="center">
        <Helmet>
          <title>Sorry, we lost this page</title>
        </Helmet>
        <Image src="/404.png" size="massive" className="squirrelChase" />
        <div className="lostPage">Sorry, we can't find this page.</div>
        <div className="neverGiveUp">...but don't give up the search!</div>
        <a href="/" className="lostHomeLink">
          Go back home
        </a>
      </Container>
    );
  }
}

export default NotFound;
