export default function FormatPresentationJson(json, key) {
  const presentations = json[key].data;
  presentations.forEach((p, index, array) => {
    p.brand = json[key].included.find((obj) => {
      return (
        obj.type === p.relationships.brand.data.type &&
        obj.id === p.relationships.brand.data.id
      );
    });
    p.event = json[key].included.find((obj) => {
      return (
        obj.type === p.relationships.event.data.type &&
        obj.id === p.relationships.event.data.id
      );
    });
    p.speakers = [];
    p.relationships.speakers.data.forEach((speaker) => {
      const s = json[key].included.find((obj) => {
        return obj.type === speaker.type && obj.id === speaker.id;
      });
      p.speakers.push(s);
    });
    p.presentation_speaker = [];
    p.relationships.presentation_speaker.data.forEach((ps) => {
      const s = json[key].included.find((obj) => {
        return obj.type === ps.type && obj.id === ps.id;
      });
      p.presentation_speaker.push(s);
    });
    p.sponsors = [];
    if (p.relationships.sponsors) {
      p.relationships.sponsors.data.forEach((sponsor) => {
        const s = json[key].included.find((obj) => {
          return obj.type === sponsor.type && obj.id === sponsor.id;
        });
        p.sponsors.push(s);
      });
    }
    array[index] = p;
  });
  return presentations;
}
