import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Container, Grid} from 'semantic-ui-react'
import Flash from '../../Flash/Flash.js'; 
import WithAuthSubscribe from '../../Auth/WithAuthSubscribe/WithAuthSubscribe';
import history from "../../../history";
import './LayoutSubscribe.css';

class LayoutSubscribe extends Component {
  constructor() {
    super()
    this.state = {
      showFlash: false,
      flashSuccess: false,
      flashMessage: ""
    };
  }

  componentDidMount() {
    if (history.location.state && history.location.state.visible) {
      this.setState({
        showFlash: history.location.state.visible,
        flashSuccess: history.location.state.success,
        flashMessage: history.location.state.message
      })
      setTimeout(
        function() {
          this.setState({
            showFlash: false,
            flashSuccess: false,
            flashMessage: ""
          })
        }
        .bind(this),
        7500
      );
    }
  }

  render() {
    return(
      <Container fluid className="subscribePageContainer">
        <Grid className="subscribePageGrid">
          <Flash visible={this.state.showFlash} success={this.state.flashSuccess} message={this.state.flashMessage} />
          <Route {...this.props} />
        </Grid>
      </Container>
    );
  }
}

export default WithAuthSubscribe(LayoutSubscribe);
