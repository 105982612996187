import * as Auth from 'devise-token-client';

export default function postAuth(url, fields, options = {}) {
  options.method = 'POST';
  options.headers = Auth.requestHeaders();
  options.headers['Accept'] = 'application/json';
  options.headers['Content-Type'] = 'application/json';
  options.body = fields

  return window
    .fetch(url, options)
    .catch(error => console.log(error))
    .then(response => {
      if (response.headers.get('access-token')) {
        Auth.persistToken(response.headers)
      }
      return response
    })
}
