import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga';
import Home from './components/Home/Home.js'
import Join from './components/Join/Join.js'
import SearchPage from './components/SearchPage/SearchPage.js'
import Register from './components/Register/Register.js'
import Attendee from './components/Attendee/Attendee.js'
import Login from './components/Login/Login.js'
import ForgotPassword from './components/ForgotPassword/ForgotPassword.js'
import ResetPassword from './components/ResetPassword/ResetPassword.js'
import Profile from './components/Profile/Profile.js'
import Favorites from './components/Favorites/Favorites.js'
import Speakers from './components/Speakers/Speakers.js'
import SpeakerPage from './components/SpeakerPage/SpeakerPage.js'
import Topics from './components/Topics/Topics.js'
import TopicPage from './components/TopicPage/TopicPage.js'
import PresentationPage from './components/PresentationPage/PresentationPage.js'
import Events from './components/Events/Events.js'
import EventPage from './components/EventPage/EventPage.js'
import Subscribe from './components/Register/Subscribe/Subscribe.js'
import Confirm from './components/Confirm/Confirm.js'
import ChoosePlan from './components/Renew/ChoosePlan/ChoosePlan.js'
import Renew from './components/Renew/Renew.js'
import NotFound from './components/NotFound/NotFound'
import LayoutProfile from './components/Layouts/LayoutProfile/LayoutProfile'
import LayoutFavorites from './components/Layouts/LayoutFavorites/LayoutFavorites'
import LayoutDevise from './components/Layouts/LayoutDevise/LayoutDevise'
import LayoutPaywall from './components/Layouts/LayoutPaywall/LayoutPaywall'
import LayoutPublic from './components/Layouts/LayoutPublic/LayoutPublic'
import LayoutJoin from './components/Layouts/LayoutJoin/LayoutJoin'
import LayoutSubscribe from './components/Layouts/LayoutSubscribe/LayoutSubscribe'
import LayoutConfirm from './components/Layouts/LayoutConfirm/LayoutConfirm'
import history from './history';


class App extends Component {
  render () {
    ReactGA.initialize('UA-133693-47')
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
      <Router history={history}>
        <Switch>
          <LayoutPublic path='/' exact component={Home} />
          <Route path='/attendee' exact component={Attendee} />
          <LayoutJoin path='/join' exact component={Join} />
          <LayoutPublic path='/search' exact component={SearchPage} />
          <LayoutPaywall path='/renew' exact component={Renew} />
          <LayoutPaywall path='/chooseplan' exact component={ChoosePlan} />
          <LayoutDevise path='/register' exact component={Register} />
          <LayoutDevise path='/login' exact component={Login} />
          <LayoutDevise path='/forgot_password' exact component={ForgotPassword} />
          <LayoutDevise path='/reset_password' exact component={ResetPassword} />
          <LayoutSubscribe path='/subscribe' exact component={Subscribe} />
          <LayoutConfirm path='/confirm' exact component={Confirm} />
          <LayoutProfile path='/profile' exact component={Profile} />
          <LayoutFavorites path='/favorites' exact component={Favorites} />
          <LayoutPublic path='/speakers' exact component={Speakers} />
          <LayoutPublic path='/speakers/:id' exact component={SpeakerPage} />
          <LayoutPublic path='/topics' exact component={Topics} />
          <LayoutPublic path='/topics/:id' exact component={TopicPage} />
          <LayoutPaywall path='/presentations/:id' exact component={PresentationPage} />
          <LayoutPublic path='/events' exact component={Events} />
          <LayoutPublic path='/events/:id' exact component={EventPage} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;