import React from 'react'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Icon } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import CompactCard from '../DisplayCard/CompactCard/CompactCard.js'; 
import useSwiperRef from './useSwiperRef/useSwiperRef.js';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import './CardSlider.css';

const CardSlider = (props) => {
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();
  return (
    <Grid.Row className="sliderRow" verticalAlign="middle">
      <Grid.Column className="swiper-column" mobile={2} tablet={2} computer={2} largeScreen={1} textAlign="center">
        <div className="swiper-icon-div" ref={prevElRef}>
          <Icon className="swiper-icon swiper-icon-left" name="chevron left" size="large" />
        </div>
      </Grid.Column>
      <Grid.Column className="swiper-center-column" mobile={12} tablet={12} computer={12} largeScreen={14}>
        <Swiper
          loop={true}
          navigation={{
            prevEl,
            nextEl,
          }}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={30}
          breakpoints={{
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 30,
            }
          }}
        >
          {props.presentations.map(presentation => {
            const uniqueId = uuid();
            return (
              <SwiperSlide key={uniqueId}>
                  <CompactCard key={`compact-card-${uniqueId}`} presentation={presentation} presentationsLoaded={true} />
                </SwiperSlide>
            )
          })}
        </Swiper>
      </Grid.Column>
      <Grid.Column className="swiper-column" mobile={2} tablet={2} computer={2} largeScreen={1} textAlign="center">
        <div className="swiper-icon-div" ref={nextElRef}>
          <Icon className="swiper-icon swiper-icon-right" name="chevron right" size="large" />
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};

export default CardSlider;
