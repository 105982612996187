import React, { Component } from 'react';
import { Button, Container, Grid, Header, Message, Transition } from 'semantic-ui-react'
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import history from "../../history";
import queryString from 'query-string';
import { RenewForm } from './RenewForm/RenewForm.js';
import fetchAuth from '../../fetchAuth.js';
import PlanSegment from '../Register/PlanSegment/PlanSegment.js';
import { Helmet } from "react-helmet-async";
import './Renew.css';

class Renew extends Component {
  constructor () {
    super()
    this.state = {
      error: false,
      errorMessage: "",
      currentUser: {},
      plan: {},
      pageLoaded: false,
      promotionCode: "",
      promotionValid: false,
      promotion: {},
      showPromotionInput: false
    };
    this.handlePromotionCode = this.handlePromotionCode.bind(this);
    this.handleOpenPromotionField = this.handleOpenPromotionField.bind(this);
    this.handleClosePromotionField = this.handleClosePromotionField.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount () {
    const search = queryString.parse(this.props.location.search)
    if ("plan" in search) {
      this.getCurrentUser();
      this.getPlan(search.plan);
    } else {
      history.push({
        pathname: "/chooseplan",
        state: {
          visible: true,
          success: false,
          message: "Please choose a subscription plan."
        }
      })
    }
  }

  getPlan (plan) {
    fetchAuth(`/api/get_plan?plan=${plan}`)
    .then(response => response.json())
    .then(json => {
      if (json) {
        this.setState(
          {
            plan: json.plan.attributes,
            pageLoaded: true
          }
        )
       }
    })
  }

  handleClick(event){
    this.setState(
      {
        promotionCode: "",
        promotionValid: false,
        promotion: {},
        showPromotionInput: false
      }
    )  
  }

  handleOpenPromotionField(event){
    this.setState(
      {
        showPromotionInput: true
      }
    )  
  }

  handleClosePromotionField(event){
    this.setState(
      {
        showPromotionInput: false
      }
    )  
  }

  handlePromotionCode(promotionCode) {
    this.setState({
      promotionCode: promotionCode
    });
    fetchAuth(`/api/get_coupon?code=${promotionCode}&plan=${this.state.plan.code}`)
    .then(response => response.json())
    .then(json => {
      if(json.errors) {
        this.handleError(json.errors);
      } else if (json.success) {
        this.setState(
          {
            promotion: json.promotion.attributes,
            showPromotionInput: false
          }
        )         
      }
      this.setState(
        {
          promotionValid: json.success
        }
      )
    })
  }

  getCurrentUser () {
    fetchAuth(`/api/profile`)
    .then(response => response.json())
    .then(json => {
      if (json) {
        this.setState(
          {
            currentUser: json.current_user
          }
        )
      }
    })
  }

  handleError(error) {
    this.setState({error: true});
    this.setState({errorMessage: error});
    setTimeout(
      function() {
        this.setState({ error: false});
        this.setState({ errorMessage: "" });
      }
      .bind(this),
      4000
    );
  }

  render() {
    const pageLoaded = this.state.pageLoaded;
    if (pageLoaded === false) {
      return null
    }
    return (
      <Container fluid className="mainContentContainer">
      <Helmet>
        <title>
          Renew 
        </title>
      </Helmet>
        <Grid container className="noNegativeMargin">
          <Container fluid className="renewPageMainContentContainer">
            <RecurlyProvider 
              publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}
              required={['cvv']}
            >
              <Elements>
                <Grid stackable className="renewPageMainContentGrid">
                  <Grid.Row reversed="tablet computer" className="renewPageMainContentRow">
                    <Grid.Column mobile={16} tablet={8} computer={8} className="rightColumn">
                      <Grid verticalAlign='middle' textAlign='center' className="authGrid">
                        <Grid.Row className="renewHeaderRow">
                          <Grid.Column width="12" textAlign="left">
                            <div className="separator">
                            </div>
                            <Header as="h2" className="renewHeader">
                              Renew
                              <Header.Subheader className="renewSubHeader">
                                Billing Information
                              </Header.Subheader>
                            </Header>
                            <div className="renewText">
                              {this.state.plan.description}
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="renewErrorRow">     
                          <Grid.Column width="12" textAlign="left">
                            <Transition visible={this.state.error} animation='scale' duration={{show: 500, hide: 0}}>
                              <Message error header={this.state.errorMessage} />
                            </Transition>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="renewFormRow">
                          <Grid.Column width="12" textAlign="left">
                            <RenewForm subscriber={this.state.currentUser} onErrorMessage={this.handleError} plan={this.state.plan} handlePromotion={this.handlePromotionCode} promotionValid={this.state.promotionValid} promotion={this.state.promotion} showPromotionInput={this.state.showPromotionInput} handleClosePromotionField={this.handleClosePromotionField} handleClick={this.handleClick} />
                            {(!this.state.showPromotionInput && Object.keys(this.state.promotion).length === 0) && (
                              <Button className="codeFieldLink" onClick={this.handleOpenPromotionField}> 
                                I have a promotion code.
                              </Button>
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} className="leftColumn">
                      <PlanSegment plan={this.state.plan} promotion={this.state.promotionCode} promotionValid={this.state.promotionValid} />
                    </Grid.Column>
                  </Grid.Row>            
                </Grid>
              </Elements>
            </RecurlyProvider>
          </Container>
        </Grid>
      </Container>    
    )
  }
}

export default Renew;