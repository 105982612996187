import React, { Component, Fragment } from 'react';
import { Button, Container, Grid, Header, Image, Menu, Segment } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import Loading from '../Loading/Loading.js';
import fetchAuth from '../../fetchAuth.js';
import history from '../../history';
import { v4 as uuid } from 'uuid';
import { Helmet } from "react-helmet-async";
import './Join.css';

class Join extends Component {
  constructor () {
    super()
    this.state = {
      showFlash: false,
      flashSuccess: false,
      flashMessage: "",
      activeIndex: 0,
      pageLoaded: false,
    };
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    this.getData();
    if (history.location.state) {
      this.setState({
        showFlash: history.location.state.visible,
        flashSuccess: history.location.state.success,
        flashMessage: history.location.state.message
      })
      setTimeout(
        function() {
          this.setState({
            showFlash: false,
            flashSuccess: false,
            flashMessage: ""
          })
        }
        .bind(this),
        7500
      );
    }
  }

  getData () {
    fetchAuth('/api/join')
    .catch(error => console.log(error))
    .then(response => response.json())
    .then(json => {
      if (json) {
        this.setState(
          {
            pageLoaded: true
          }
        )
      }
    })
  }

  handleClick(event, titleProps) {
    const index = titleProps.index
    this.setState({ activeIndex: index })
  }

  formatDate(date) {
    const data = new Date(date);  // 2009-11-10
    const writtenDate = data.getTime();
    return writtenDate;
  }

  makeArray(array, size) {
    var i,j,tempArray,chunk = size, newArray = [];
    for (i=0,j=array.length; i<j; i+=chunk) {
        tempArray = array.slice(i,i+chunk);
        newArray.push(tempArray)
    }
    return newArray;
  }
  
  render() {
    const mobileImages_1 = ['amazon.png', 'best_buy.png', 'charter_communications.png', 'geico.png']
    const mobileImages_2 = ['hilton.png', 'salesforce.png','trinity_health.png', 'walgreens.png']
    const largeScreenImages_1 = ['amazon.png', 'audible.png', 'best_buy.png', 'charter_communications.png', 'doordash.png', 'geico.png']
    const largeScreenImages_2 = ['hilton.png', 'new_york_times.png', 'reddit.png', 'salesforce.png', 'trinity_health.png', 'walgreens.png']
    const pageLoaded = this.state.pageLoaded;
    if (pageLoaded === false) {
      return (
        <Loading />
      )
    }
    return (
      <Fragment>
        <Helmet>
          <title>
            Recruiting & Talent Acquisition KnowledgeBase 
          </title>
        </Helmet>
        <Container fluid className="homeTopContainer topContainer">
          <div className="homeTopFilter">
            <Menu text borderless attached size="massive" className="joinMenu">
              <Menu.Item
                icon={this.props.icon}
                onClick={this.props.toggleSideBar}
                className="menuToggle"
              />
            </Menu>
            <Grid>
              <Grid.Row centered className = "logoRow">
                <Grid.Column mobile={12} tablet={10} computer={10} textAlign="center">
                  <Image centered src='ere_pro_logo_white.png' size='medium' />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column mobile={16} tablet={10} computer={10} textAlign="center">
                  <Header as="h1" className="white homeTopHeader">
                    The World's Largest Library of Talent Acquisition Media
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Container>

        <Container fluid className="subscriptionContainer topSubscriptionContainer" id="subscribe">
          <Container>
            <Grid className="subscriptionGrid">
              <Grid.Row centered className="subscriptionRow">
                <Grid.Column mobile={16} tablet={10} computer={10} textAlign="center">
                  <div className="separator"></div>
                  <Header as="h2" className="white subscriptionHeader">
                    Join ERE Pro
                    <Header.Subheader className="subscriptionSubHeader">
                      Free 7 Day Trial
                    </Header.Subheader>
                  </Header>
                  <div as="h4" className="subscriptionText white">
                    Watch exclusive lectures, learn from unique experiences and pick up proven strategies from talent acquisition leaders. Discover fresh ideas every month.
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid stackable className="optionsGrid">
                <Grid.Row centered>
                  <Grid.Column mobile={8} tablet={6} computer={5}>
                    <Segment
                      padded="very"
                      as={Link}
                      to={{
                        pathname: "/register",
                        search: "?plan=quarterly",
                      }}
                    >
                      <Grid centered>
                        <Grid.Row>
                          <Header size='medium'>Quarterly Plan</Header>
                        </Grid.Row>
                        <Grid.Row>
                          <Header size='huge'>$89.99</Header>
                        </Grid.Row>
                        <Grid.Row className="dailyPrice">
                          Only $1 / Day
                        </Grid.Row>
                        <Grid.Row>
                        <Button primary className="subscribeButton">Try it free for a week!</Button>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column only="tablet computer" computer={2}>

                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={6} computer={5}>
                    <Segment
                      raised
                      padded="very"
                      as={Link}
                      to={{
                        pathname: "/register",
                        search: "?plan=annual",
                      }}
                    >
                      <Grid centered>
                        <Grid.Row>
                          <Header size='medium'>Annual Plan</Header>
                        </Grid.Row>
                        <Grid.Row>
                          <Header size='huge'>$239.99</Header>
                        </Grid.Row>
                        <Grid.Row className="dailyPrice">
                          Only 66&#162; / Day
                        </Grid.Row>
                        <Grid.Row>
                        <Button primary className="subscribeButton">Try it free for a week!</Button>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid>
            </Container>
          </Container>

        <Container fluid className="featuresContainer first">
          <div className="treesFilter">
            <Container>
              <Grid stackable relaxed="very" className="featuresGrid">
                <Grid.Row className="featuresRow" verticalAlign="middle">
                  <Grid.Column mobile={16} tablet={10} computer={10} className="featuresTextLeft">
                    <div className="separator"></div>
                    <Header as="h2" className="featuresHeader">
                      Talent Acquisition Knowledge
                    <Header.Subheader className="featuresSubHeader">
                      On Demand
                    </Header.Subheader>
                  </Header>
                    <div className="featuresText">
                      ERE Pro provides you with unlimited access to an unrivaled knowledgebase of hundreds of professionals sharing their Talent Acquisition expertise.
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
        </Container>
        <Container fluid className="featuresContainer second">
          <Container>
            <Grid stackable relaxed="very" className="featuresGrid">
              <Grid.Row reversed="tablet computer" className="featuresRow" verticalAlign="middle">
                <Grid.Column mobile={16} tablet={10} computer={10} className="featuresTextRight">
                  <div className="separator"></div>
                  <Header as="h2" className="white featuresHeader">
                    Deep Dives into Recruiting 
                  <Header.Subheader className="white featuresSubHeader">
                    From the Experts
                  </Header.Subheader>
                </Header>
                  <div className="white featuresText">
                    Your CEO just asked you for a new Employee Referral Program. Your VP of HR demands ideas to make your workforce more diverse. ERE Pro has deep dives into the recruiting challenges that real practitioners face daily, and has expertise from leaders that have already tackled them.
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Container>
        <Container fluid className="featuresContainer third">
          <Container>
            <Grid relaxed="very" stackable className="featuresGrid">
              <Grid.Row className="featuresRow" verticalAlign="middle">
                <Grid.Column mobile={16} tablet={10} computer={10} className="featuresTextLeft">
                  <div className="separator">
                  </div>
                  <Header as="h2" className="featuresHeader">
                    Experienced Leaders
                    <Header.Subheader className="featuresSubHeader">
                      Unique Perspectives
                    </Header.Subheader>
                  </Header>
                  <div className="featuresText">
                    ERE Pro is meticulously curated by our editorial team. We identify top Talent Acquisition leaders to deliver insights from their own experiences in the profession, direct to you.
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Container>

        <Container fluid className="companyContainer">
            <Grid relaxed="very" stackable className="companyGrid">
              <Grid.Row>
                <Grid.Column only="tablet computer" className="leftColumn" tablet={6} computer={6}>
                </Grid.Column>
                <Grid.Column className="rightColumn" mobile={16} tablet={10} computer={10}>
                  <Grid>
                    <Grid.Row className="companyHeaderRow">
                      <Grid.Column>
                        <div className="separator"></div>
                        <Header as="h2" className="companyHeader">
                          Used by Great Recruiters
                          <Header.Subheader className="companySubHeader">
                            Building Amazing Teams
                          </Header.Subheader>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered verticalAlign="middle" columns={4} className="companyLogoRow" only="mobile">
                      {mobileImages_1.map((image, index) => {
                        const uniqueId = uuid();
                        return (
                          <Grid.Column verticalAlign="middle" key={uniqueId}>
                            <Image src={'/logos/' + image} size="tiny" className="companyLogo" />
                          </Grid.Column>  
                        )
                      })}
                    </Grid.Row>
                    <Grid.Row centered verticalAlign="middle" columns={4} className="companyLogoRow" only="mobile">
                      {mobileImages_2.map((image, index) => {
                        const uniqueId = uuid();
                        return (
                          <Grid.Column verticalAlign="middle" key={uniqueId}>
                            <Image src={'/logos/' + image}  className="companyLogo" />
                          </Grid.Column>  
                        )
                      })}
                    </Grid.Row>
                    <Grid.Row centered verticalAlign="middle" columns={6} className="companyLogoRow" only="tablet computer">
                      {largeScreenImages_1.map((image, index) => {
                        const uniqueId = uuid();
                        return (
                          <Grid.Column verticalAlign="middle" key={uniqueId}>
                            <Image src={'/logos/' + image} size="tiny" className="companyLogo" />
                          </Grid.Column>  
                        )
                      })}
                    </Grid.Row>
                    <Grid.Row centered verticalAlign="middle" columns={6} className="companyLogoRow" only="tablet computer">
                      {largeScreenImages_2.map((image, index) => {
                        const uniqueId = uuid();
                        return (
                          <Grid.Column verticalAlign="middle" key={uniqueId}>
                            <Image src={'/logos/' + image}  className="companyLogo" />
                          </Grid.Column>  
                        )
                      })}
                    </Grid.Row>
                    </Grid>

                </Grid.Column>
              </Grid.Row>
            </Grid>
        </Container>

        <Container fluid className="subscriptionContainer bottomSubscriptionContainer">
          <Container>
            <Grid className="subscriptionGrid">
              <Grid.Row centered className="subscriptionRow">
                <Grid.Column mobile={16} tablet={10} computer={10} textAlign="center">
                  <div className="separator"></div>
                  <Header as="h2" className="subscriptionHeader">
                    Join ERE Pro
                    <Header.Subheader className="subscriptionSubHeader">
                      Free 7 Day Trial
                    </Header.Subheader>
                  </Header>
                  <div as="h4" className="subscriptionText">
                    Watch exclusive lectures, learn from unique experiences and pick up proven strategies from talent acquisition leaders. Discover fresh ideas every month.
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid stackable className="optionsGrid">
                <Grid.Row centered>
                  <Grid.Column mobile={8} tablet={6} computer={5}>
                    <Segment
                      padded="very"
                      as={Link}
                      to={{
                        pathname: "/register",
                        search: "?plan=quarterly",
                      }}
                    >
                      <Grid centered>
                        <Grid.Row>
                          <Header size='medium'>Quarterly Plan</Header>
                        </Grid.Row>
                        <Grid.Row>
                          <Header size='huge'>$89.99</Header>
                        </Grid.Row>
                        <Grid.Row className="dailyPrice">
                          Only $1 / Day
                        </Grid.Row>
                        <Grid.Row>
                        <Button primary className="subscribeButton">Try it free for a week!</Button>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column only="tablet computer" computer={2}>

                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={6} computer={5}>
                    <Segment
                      raised
                      padded="very"
                      as={Link}
                      to={{
                        pathname: "/register",
                        search: "?plan=annual",
                      }}
                    >
                      <Grid centered>
                        <Grid.Row>
                          <Header size='medium'>Annual Plan</Header>
                        </Grid.Row>
                        <Grid.Row>
                          <Header size='huge'>$239.99</Header>
                        </Grid.Row>
                        <Grid.Row className="dailyPrice">
                          Only 66&#162; / Day
                        </Grid.Row>
                        <Grid.Row>
                        <Button primary className="subscribeButton">Try it free for a week!</Button>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid>
            </Container>
          </Container>


      </Fragment>
    );
  }
}

export default Join;
